// Vendor
import { gettext } from '@eventbrite/i18n';
import React from 'react';
// Internal
import './DropdownMenuImage.scss';

export interface IDropdownMenuImageProps {
    imageUrl: string;
}

const DropdownMenuImage = ({ imageUrl }: IDropdownMenuImageProps) => (
    <span className="eds-global-header__menu-profile-image--container">
        <img
            className="eds-global-header__menu-profile-image"
            src={imageUrl}
            alt={gettext('Profile Image').toString()}
            data-testid="menu-profile-image"
        />
        <div className="eds-global-header__menu-profile-image--border" />
    </span>
);

export default DropdownMenuImage;
