// Vendor
import { AvatarProps } from '@eventbrite/eds-avatar';
import findKey from 'lodash/findKey';
// Internal
import DropdownMenuAvatar, {
    IDropdownMenuAvatarProps,
} from '../DropdownMenuAvatar';
import DropdownMenuIcon, { IDropdownMenuIconProps } from '../DropdownMenuIcon';
import DropdownMenuImage, {
    IDropdownMenuImageProps,
} from '../DropdownMenuImage';
import DropdownMenuNarrowOnlyIcon, {
    IDropdownMenuNarrowOnlyIconProps,
} from '../DropdownMenuNarrowOnlyIcon';

interface IHelperTypesArgs {
    avatarProps?: AvatarProps;
    imageUrl?: string;
    iconType?: JSX.Element;
    narrowIconType?: JSX.Element;
}

interface IHelperTypesResult {
    avatar: {
        test: () => any;
        fn: ({ avatarProps }: IDropdownMenuAvatarProps) => JSX.Element;
    };
    image: {
        test: () => any;
        fn: ({ imageUrl }: IDropdownMenuImageProps) => JSX.Element;
    };
    icon: {
        test: () => any;
        fn: ({
            iconType,
            narrowIconType,
            iconColor,
            narrowIconColor,
        }: IDropdownMenuIconProps) => JSX.Element;
    };
    narrowIcon: {
        test: () => any;
        fn: ({
            narrowIconType,
            narrowIconColor,
        }: IDropdownMenuNarrowOnlyIconProps) => JSX.Element;
    };
}

type THelperTypes = (args: IHelperTypesArgs) => IHelperTypesResult;
type TGetType = (
    args: IHelperTypesArgs,
) => (({ avatarProps }: any) => JSX.Element) | null;

export const getHelperTypes: THelperTypes = ({
    avatarProps,
    imageUrl,
    iconType,
    narrowIconType,
}) => ({
    avatar: { test: () => avatarProps, fn: DropdownMenuAvatar },
    image: { test: () => imageUrl, fn: DropdownMenuImage },
    icon: { test: () => iconType, fn: DropdownMenuIcon },
    narrowIcon: {
        test: () => !iconType && narrowIconType,
        fn: DropdownMenuNarrowOnlyIcon,
    },
});

export const _getType: TGetType = (props) => {
    const types = getHelperTypes(props);

    const type = findKey(types, (value) => value.test()) as
        | keyof IHelperTypesResult
        | undefined;

    let Component = null;

    if (type) {
        Component = types[type].fn;
    }

    return Component;
};
