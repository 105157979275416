import { CoreEnv } from '@eventbrite/context-gen';
import React from 'react';
import ContactSales from './components/contactSales';
import { FlexiblePricing, STARTING_PRICES } from './components/FlexiblePricing';
import SimplePricing from './components/SimplePricing/SimplePricing';

type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

const CONTACT_SALES_SUPPORTED_TLDS = [
    '.com', // United States
    '.ca', // Canada
    '.co.uk', // United Kingdom
    '.com.au', // Australia
    '.ie', // Ireland
    '.co.nz', // New Zealand
    '.de', // Deutschland
];

export type RightSizePricingProps = {
    serviceFee: string;
    processingFee?: string;
    renderInnerComponentOnly?: boolean;
    env?: DeepPartial<CoreEnv>;
};

export const RightSizePricingComponent: React.FC<RightSizePricingProps> = (
    props,
) => {
    const { env, serviceFee, processingFee, renderInnerComponentOnly } = props;

    const tld = env?.localeInfo?.tld || '.com';
    const enableFlexiblePricingModule = !!STARTING_PRICES[tld];

    const comparePricingCTALink = enableFlexiblePricingModule
        ? '/organizer/pricing/'
        : '/organizer/compare-packages/';

    const supportSalesContact = CONTACT_SALES_SUPPORTED_TLDS.includes(tld);

    const innerComponent = (
        <>
            {enableFlexiblePricingModule ? (
                <>
                    <FlexiblePricing
                        tld={tld}
                        serviceFee={serviceFee}
                        processingFee={processingFee}
                    />
                </>
            ) : (
                <>
                    <SimplePricing
                        serviceFee={serviceFee}
                        processingFee={processingFee}
                    />
                </>
            )}
            <ContactSales
                renderFlexiblePricing={enableFlexiblePricingModule || false}
                supportSalesContact={supportSalesContact}
                comparePricingCTALink={comparePricingCTALink}
            />
        </>
    );

    return (
        <>
            {renderInnerComponentOnly ? (
                innerComponent
            ) : (
                <section>
                    <div className="module-landing-page__module-container-content">
                        {innerComponent}
                    </div>
                </section>
            )}
        </>
    );
};
