import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { useEffect, useState } from 'react';
import { EBreakpointValues, ESizes } from '../types';

export type ISizes = Record<ESizes, boolean>;
export type IQuerySizes = Record<ESizes, string>;

// Manually creating the array from the ENUM and using `as const` to enforce ordering
export const OrderedSizes: Readonly<ESizes[]> = [
    ESizes.xlarge,
    ESizes.large,
    ESizes.medium,
    ESizes.small,
] as const;

const SizeToQuery: IQuerySizes = {
    xlarge: `(min-width: ${EBreakpointValues.xlarge}px)`,
    large: `(min-width: ${EBreakpointValues.large}px)`,
    medium: `(min-width: ${EBreakpointValues.medium}px)`,
    small: `(min-width: ${EBreakpointValues.small}px)`,
};

const getLargestActiveMediaSize = (sizes: ISizes) => {
    return OrderedSizes.find((size) => sizes[size]) || OrderedSizes[0];
};

const getMediaObject = () => {
    return OrderedSizes.reduce(
        (acc, size) => ({
            ...acc,
            [size]: HAS_WINDOW
                ? window.matchMedia(SizeToQuery[size]).matches
                : false,
        }),
        {},
    ) as ISizes;
};

const didMediaChange = (media: ISizes, sizes: ISizes) => {
    return !!OrderedSizes.find((size) => media[size] !== sizes[size]);
};

export const useMediaQuery = () => {
    const [sizes, setSizes] = useState<ISizes>(getMediaObject());

    useEffect(() => {
        const listener = () => {
            const media = getMediaObject();
            if (didMediaChange(media, sizes)) {
                setSizes(media);
            }
        };
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, [sizes]);

    return getLargestActiveMediaSize(sizes);
};
