import * as React from 'react';

const HamburgerMenuSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" {...props}>
        <g id="hamburger-menu_svg__Frame_2970">
            <g id="hamburger-menu_svg__24_align_justify">
                <g id="hamburger-menu_svg___align_justify">
                    <path
                        id="hamburger-menu_svg__Icon"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 5h16v2H4V5zm0 6h16v2H4v-2zm16 6H4v2h16v-2z"
                        fill="#4B4D63"
                    />
                </g>
            </g>
        </g>
    </svg>
);

HamburgerMenuSvg.displayName = 'HamburgerMenuSvg';
export default HamburgerMenuSvg;
