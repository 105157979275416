import { getAnalyticsClickHandler as getAnalyticsHandler } from '@eventbrite/site-analytics';
import { MouseEventHandler } from 'react';
import {
    GA_ACTION_ACCOUNT_SETTINGS_CLICK,
    GA_ACTION_ACCOUNT_SETTINGS_HOVER,
    GA_ACTION_ADMIN_CLICK,
    GA_ACTION_ADMIN_HOVER,
    GA_ACTION_ADS_CLICK,
    GA_ACTION_ADS_HOVER,
    GA_ACTION_BLOG_CLICK,
    GA_ACTION_BLOG_HOVER,
    GA_ACTION_BROWSE_EVENTS_CLICK,
    GA_ACTION_BROWSE_EVENTS_HOVER,
    GA_ACTION_CHARITY_AND_CAUSES_CLICK,
    GA_ACTION_CHARITY_AND_CAUSES_HOVER,
    GA_ACTION_CLASSES_AND_WORKSHOPS_CLICK,
    GA_ACTION_CLASSES_AND_WORKSHOPS_HOVER,
    GA_ACTION_COLLECTIONS_CLICK,
    GA_ACTION_COLLECTIONS_HOVER,
    GA_ACTION_COMMUNITY_CLICK,
    GA_ACTION_COMMUNITY_HOVER,
    GA_ACTION_CONCERTS_CLICK,
    GA_ACTION_CONCERTS_HOVER,
    GA_ACTION_CONFERENCES_CLICK,
    GA_ACTION_CONFERENCES_HOVER,
    GA_ACTION_CONTACT_SALES_CLICK,
    GA_ACTION_CONTACT_SALES_HOVER,
    GA_ACTION_CORPORATE_EVENTS_CLICK,
    GA_ACTION_CORPORATE_EVENTS_HOVER,
    GA_ACTION_CREDITS_CLICK,
    GA_ACTION_CREDITS_HOVER,
    GA_ACTION_EVENT_MARKETING_SUITE_CLICK,
    GA_ACTION_EVENT_MARKETING_SUITE_HOVER,
    GA_ACTION_EVENT_TICKETING_CLICK,
    GA_ACTION_EVENT_TICKETING_HOVER,
    GA_ACTION_EVENT_TYPES_CLICK,
    GA_ACTION_EVENT_TYPES_HOVER,
    GA_ACTION_FEATURE_FLAGS_CLICK,
    GA_ACTION_FEATURE_FLAGS_HOVER,
    GA_ACTION_FESTIVALS_AND_FAIRS_CLICK,
    GA_ACTION_FESTIVALS_AND_FAIRS_HOVER,
    GA_ACTION_FESTIVAL_AND_FAIRS_CLICK,
    GA_ACTION_FESTIVAL_AND_FAIRS_HOVER,
    GA_ACTION_FOLLOWING_CLICK,
    GA_ACTION_FOLLOWING_HOVER,
    GA_ACTION_FOOD_AND_BEVERAGE_CLICK,
    GA_ACTION_FOOD_AND_BEVERAGE_HOVER,
    GA_ACTION_HEALTH_AND_FITNESS_CLICK,
    GA_ACTION_HEALTH_AND_FITNESS_HOVER,
    // GA ACTIONS
    GA_ACTION_HOST_AN_EVENT_CLICK,
    GA_ACTION_INDUSTRY_CLICK,
    GA_ACTION_INDUSTRY_HOVER,
    GA_ACTION_INTERESTS_CLICK,
    GA_ACTION_INTERESTS_HOVER,
    GA_ACTION_LIKES_CLICK,
    GA_ACTION_LIKES_HOVER,
    GA_ACTION_LOGIN_CLICK,
    GA_ACTION_LOGIN_HOVER,
    GA_ACTION_LOGOUT_CLICK,
    GA_ACTION_LOGOUT_HOVER,
    GA_ACTION_MANAGE_EVENTS_CLICK,
    GA_ACTION_MANAGE_EVENTS_HOVER,
    GA_ACTION_MUSIC_CLICK,
    GA_ACTION_MUSIC_HOVER,
    GA_ACTION_NIGHT_LIFE_CLICK,
    GA_ACTION_NIGHT_LIFE_HOVER,
    GA_ACTION_ONLINE_EVENTS_CLICK,
    GA_ACTION_ONLINE_EVENTS_HOVER,
    GA_ACTION_PAYMENTS_CLICK,
    GA_ACTION_PAYMENTS_HOVER,
    GA_ACTION_PAYMENTS_SUITE_CLICK,
    GA_ACTION_PAYMENTS_SUITE_HOVER,
    GA_ACTION_PERFORMING_AND_VISUAL_ARTS_CLICK,
    GA_ACTION_PERFORMING_AND_VISUAL_ARTS_HOVER,
    GA_ACTION_PRICING_CLICK,
    GA_ACTION_PRICING_HOVER,
    GA_ACTION_PROFESSIONAL_SERVICES_CLICK,
    GA_ACTION_PROFESSIONAL_SERVICES_HOVER,
    GA_ACTION_RESOURCES_CLICK,
    GA_ACTION_RESOURCES_HOVER,
    GA_ACTION_RETAIL_CLICK,
    GA_ACTION_RETAIL_HOVER,
    GA_ACTION_SIGNUP_CLICK,
    GA_ACTION_SIGNUP_HOVER,
    GA_ACTION_SOLUTIONS_CLICK,
    GA_ACTION_SOLUTIONS_HOVER,
    GA_ACTION_SUCCESS_STORIES_CLICK,
    GA_ACTION_SUCCESS_STORIES_HOVER,
    GA_ACTION_SUPPORT_CLICK,
    GA_ACTION_SUPPORT_HOVER,
    GA_ACTION_TICKETS_CLICK,
    GA_ACTION_TICKETS_HOVER,
    GA_ACTION_TIKIBAR_CLICK,
    GA_ACTION_TIKIBAR_HOVER,
    GA_ACTION_WHY_EVENTBRITE_CLICK,
    GA_ACTION_WHY_EVENTBRITE_HOVER,
    // GA CATEGORIES
    GA_CATEGORY_MLP_HEADER,
    // GA LABELS
    GA_LABEL_ACCOUNT_SETTINGS,
    GA_LABEL_ADMIN,
    GA_LABEL_ADS,
    GA_LABEL_BLOG,
    GA_LABEL_BROWSE_EVENTS,
    GA_LABEL_CHARITY_AND_CAUSES,
    GA_LABEL_CLASSES_AND_WORKSHOPS,
    GA_LABEL_COLLECTIONS,
    GA_LABEL_COMMUNITY,
    GA_LABEL_CONCERTS,
    GA_LABEL_CONFERENCES,
    GA_LABEL_CONTACT_SALES,
    GA_LABEL_CORPORATE_EVENTS,
    GA_LABEL_CREDITS,
    GA_LABEL_EVENT_MARKETING_SUITE,
    GA_LABEL_EVENT_TICKETING,
    GA_LABEL_EVENT_TYPES,
    GA_LABEL_FEATURE_FLAGS,
    GA_LABEL_FESTIVAL_AND_FAIRS,
    GA_LABEL_FOLLOWING,
    GA_LABEL_FOOD_AND_BEVERAGE,
    GA_LABEL_HEALTH_AND_FITNESS,
    GA_LABEL_INDUSTRY,
    GA_LABEL_INTERESTS,
    GA_LABEL_LIKES,
    GA_LABEL_LOGIN,
    GA_LABEL_LOGOUT,
    GA_LABEL_MANAGE_EVENTS,
    GA_LABEL_MUSIC,
    GA_LABEL_NIGHT_LIFE,
    GA_LABEL_ONLINE_EVENTS,
    GA_LABEL_PAYMENTS,
    GA_LABEL_PAYMENTS_SUITE,
    GA_LABEL_PERFORMING_AND_VISUAL_ARTS,
    GA_LABEL_PRICING,
    GA_LABEL_PROFESSIONAL_SERVICES,
    GA_LABEL_RESOURCES,
    GA_LABEL_RETAIL,
    GA_LABEL_SIGNUP,
    GA_LABEL_SOLUTIONS,
    GA_LABEL_SUCCESS_STORIES,
    GA_LABEL_SUPPORT,
    GA_LABEL_TICKETS,
    GA_LABEL_TIKIBAR,
    GA_LABEL_WHY_EVENTBRITE,
} from '../constants/tracking';

const actions = {
    GA_ACTION_HOST_AN_EVENT_CLICK,
    GA_ACTION_SOLUTIONS_CLICK,
    GA_ACTION_SOLUTIONS_HOVER,
    GA_ACTION_INDUSTRY_HOVER,
    GA_ACTION_INDUSTRY_CLICK,
    GA_ACTION_EVENT_TYPES_CLICK,
    GA_ACTION_EVENT_TYPES_HOVER,
    GA_ACTION_RESOURCES_HOVER,
    GA_ACTION_RESOURCES_CLICK,
    GA_ACTION_PRICING_HOVER,
    GA_ACTION_PRICING_CLICK,
    GA_ACTION_LOGIN_HOVER,
    GA_ACTION_LOGIN_CLICK,
    GA_ACTION_SIGNUP_HOVER,
    GA_ACTION_SIGNUP_CLICK,
    GA_ACTION_EVENT_TICKETING_HOVER,
    GA_ACTION_EVENT_TICKETING_CLICK,
    GA_ACTION_EVENT_MARKETING_SUITE_HOVER,
    GA_ACTION_EVENT_MARKETING_SUITE_CLICK,
    GA_ACTION_PAYMENTS_HOVER,
    GA_ACTION_PAYMENTS_CLICK,
    GA_ACTION_WHY_EVENTBRITE_HOVER,
    GA_ACTION_WHY_EVENTBRITE_CLICK,
    GA_ACTION_COMMUNITY_HOVER,
    GA_ACTION_COMMUNITY_CLICK,
    GA_ACTION_SUCCESS_STORIES_HOVER,
    GA_ACTION_SUCCESS_STORIES_CLICK,
    GA_ACTION_BLOG_HOVER,
    GA_ACTION_BLOG_CLICK,
    GA_ACTION_CONTACT_SALES_HOVER,
    GA_ACTION_CONTACT_SALES_CLICK,
    GA_ACTION_SUPPORT_HOVER,
    GA_ACTION_SUPPORT_CLICK,
    GA_ACTION_CONCERTS_HOVER,
    GA_ACTION_CONCERTS_CLICK,
    GA_ACTION_CLASSES_AND_WORKSHOPS_HOVER,
    GA_ACTION_CLASSES_AND_WORKSHOPS_CLICK,
    GA_ACTION_FESTIVALS_AND_FAIRS_HOVER,
    GA_ACTION_FESTIVALS_AND_FAIRS_CLICK,
    GA_ACTION_NIGHT_LIFE_HOVER,
    GA_ACTION_NIGHT_LIFE_CLICK,
    GA_ACTION_CONFERENCES_HOVER,
    GA_ACTION_CONFERENCES_CLICK,
    GA_ACTION_CORPORATE_EVENTS_HOVER,
    GA_ACTION_CORPORATE_EVENTS_CLICK,
    GA_ACTION_ONLINE_EVENTS_HOVER,
    GA_ACTION_ONLINE_EVENTS_CLICK,
    GA_ACTION_MUSIC_HOVER,
    GA_ACTION_MUSIC_CLICK,
    GA_ACTION_FOOD_AND_BEVERAGE_HOVER,
    GA_ACTION_FOOD_AND_BEVERAGE_CLICK,
    GA_ACTION_PERFORMING_AND_VISUAL_ARTS_HOVER,
    GA_ACTION_PERFORMING_AND_VISUAL_ARTS_CLICK,
    GA_ACTION_PROFESSIONAL_SERVICES_HOVER,
    GA_ACTION_PROFESSIONAL_SERVICES_CLICK,
    GA_ACTION_CHARITY_AND_CAUSES_HOVER,
    GA_ACTION_CHARITY_AND_CAUSES_CLICK,
    GA_ACTION_HEALTH_AND_FITNESS_HOVER,
    GA_ACTION_HEALTH_AND_FITNESS_CLICK,
    GA_ACTION_RETAIL_HOVER,
    GA_ACTION_RETAIL_CLICK,
    GA_ACTION_MANAGE_EVENTS_HOVER,
    GA_ACTION_MANAGE_EVENTS_CLICK,
    GA_ACTION_ADMIN_HOVER,
    GA_ACTION_ADMIN_CLICK,
    GA_ACTION_TIKIBAR_HOVER,
    GA_ACTION_TIKIBAR_CLICK,
    GA_ACTION_FEATURE_FLAGS_HOVER,
    GA_ACTION_FEATURE_FLAGS_CLICK,
    GA_ACTION_ACCOUNT_SETTINGS_HOVER,
    GA_ACTION_ACCOUNT_SETTINGS_CLICK,
    GA_ACTION_COLLECTIONS_HOVER,
    GA_ACTION_COLLECTIONS_CLICK,
    GA_ACTION_CREDITS_HOVER,
    GA_ACTION_CREDITS_CLICK,
    GA_ACTION_FOLLOWING_HOVER,
    GA_ACTION_FOLLOWING_CLICK,
    GA_ACTION_INTERESTS_HOVER,
    GA_ACTION_INTERESTS_CLICK,
    GA_ACTION_LIKES_HOVER,
    GA_ACTION_LIKES_CLICK,
    GA_ACTION_LOGOUT_HOVER,
    GA_ACTION_LOGOUT_CLICK,
    GA_ACTION_TICKETS_HOVER,
    GA_ACTION_TICKETS_CLICK,
    GA_ACTION_BROWSE_EVENTS_HOVER,
    GA_ACTION_BROWSE_EVENTS_CLICK,
    GA_ACTION_PAYMENTS_SUITE_CLICK,
    GA_ACTION_PAYMENTS_SUITE_HOVER,
    GA_ACTION_FESTIVAL_AND_FAIRS_HOVER,
    GA_ACTION_FESTIVAL_AND_FAIRS_CLICK,
    GA_ACTION_ADS_HOVER,
    GA_ACTION_ADS_CLICK,
};
const labels = {
    GA_LABEL_ACCOUNT_SETTINGS,
    GA_LABEL_ADMIN,
    GA_LABEL_ADS,
    GA_LABEL_BLOG,
    GA_LABEL_BROWSE_EVENTS,
    GA_LABEL_CHARITY_AND_CAUSES,
    GA_LABEL_CLASSES_AND_WORKSHOPS,
    GA_LABEL_COLLECTIONS,
    GA_LABEL_COMMUNITY,
    GA_LABEL_CONCERTS,
    GA_LABEL_CONFERENCES,
    GA_LABEL_CONTACT_SALES,
    GA_LABEL_CORPORATE_EVENTS,
    GA_LABEL_CREDITS,
    GA_LABEL_EVENT_MARKETING_SUITE,
    GA_LABEL_EVENT_TICKETING,
    GA_LABEL_EVENT_TYPES,
    GA_LABEL_FEATURE_FLAGS,
    GA_LABEL_FESTIVAL_AND_FAIRS,
    GA_LABEL_FOLLOWING,
    GA_LABEL_FOOD_AND_BEVERAGE,
    GA_LABEL_HEALTH_AND_FITNESS,
    GA_LABEL_INDUSTRY,
    GA_LABEL_INTERESTS,
    GA_LABEL_LIKES,
    GA_LABEL_LOGIN,
    GA_LABEL_LOGOUT,
    GA_LABEL_MANAGE_EVENTS,
    GA_LABEL_MUSIC,
    GA_LABEL_NIGHT_LIFE,
    GA_LABEL_ONLINE_EVENTS,
    GA_LABEL_PAYMENTS,
    GA_LABEL_PAYMENTS_SUITE,
    GA_LABEL_PERFORMING_AND_VISUAL_ARTS,
    GA_LABEL_PRICING,
    GA_LABEL_PROFESSIONAL_SERVICES,
    GA_LABEL_RESOURCES,
    GA_LABEL_RETAIL,
    GA_LABEL_SIGNUP,
    GA_LABEL_SOLUTIONS,
    GA_LABEL_WHY_EVENTBRITE,
    GA_LABEL_SUCCESS_STORIES,
    GA_LABEL_SUPPORT,
    GA_LABEL_TICKETS,
    GA_LABEL_TIKIBAR,
};

export type TLabels = keyof typeof labels;
export type TActions = keyof typeof actions;

interface ITrackProps {
    label: keyof typeof labels;
    action: keyof typeof actions;
    category?: string;
}

export const trackHeaderMetric: (props: ITrackProps) => MouseEventHandler = ({
    action,
    label,
    category = GA_CATEGORY_MLP_HEADER,
}) =>
    getAnalyticsHandler({
        action: actions[action],
        category,
        label: labels[label],
    });
