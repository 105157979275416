interface ILinkType {
    PILL: 'pill';
    LINK: 'link';
    LINK_BLUE: 'link-blue';
}

export const LINK_TYPE: ILinkType = {
    PILL: 'pill',
    LINK: 'link',
    LINK_BLUE: 'link-blue',
};
