import { CheckChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { Button as MarmaladeButton } from '@eventbrite/marmalade';
import React from 'react';
import './simplePricing.scss';

export interface SimplePricingProps {
    serviceFee: string;
    processingFee?: string;
}

const SimplePricing: React.FC<SimplePricingProps> = ({
    serviceFee,
    processingFee,
}) => (
    <section className="simple-pricing">
        <div className="simple-pricing__content">
            <div className="simple-pricing__content-item--half-margin">
                <span className="simple-pricing__mktg-text">
                    {gettext('No upfront fees')}
                </span>
                <h2>{gettext('Simple pricing, clear value')}</h2>
            </div>
            <p className="simple-pricing__content-item--description">
                {gettext(
                    "Create your event on the world's largest event marketplace at no cost to you. We've got everything you need to sell tickets, manage your events, and grow your business.",
                )}
            </p>
            <a href="/signin/signup/?referrer=/manage/events/create/">
                <MarmaladeButton size="block" variant="primary">
                    {gettext('Get started for free')}
                </MarmaladeButton>
            </a>
        </div>
        <ul className="simple-pricing__content simple-pricing__content--no-left-padding-responsive simple-pricing__content--half-margin simple-pricing__list">
            <li>
                <CheckChunky
                    className="simple-pricing__checkmark"
                    aria-hidden="true"
                />
                <span className="simple-pricing__list__text">
                    {gettext(
                        'Flexible ticket types for in-person or online events',
                    )}
                </span>
            </li>
            <li>
                <CheckChunky
                    className="simple-pricing__checkmark"
                    aria-hidden="true"
                />
                <span className="simple-pricing__list__text">
                    {gettext('Unlimited listings in our marketplace')}
                </span>
            </li>
            <li>
                <CheckChunky
                    className="simple-pricing__checkmark"
                    aria-hidden="true"
                />
                <span className="simple-pricing__list__text">
                    {gettext(
                        'Integrated email and social media marketing tools',
                    )}
                </span>
            </li>
            <li>
                <CheckChunky
                    className="simple-pricing__checkmark"
                    aria-hidden="true"
                />
                <span className="simple-pricing__list__text">
                    {gettext('Ability to sell tickets on your own site')}
                </span>
            </li>
            <li>
                <CheckChunky
                    className="simple-pricing__checkmark"
                    aria-hidden="true"
                />
                <span className="simple-pricing__list__text">
                    {gettext('Seamless attendee checkout experience')}
                </span>
            </li>
            <li>
                <CheckChunky
                    className="simple-pricing__checkmark"
                    aria-hidden="true"
                />
                <span className="simple-pricing__list__text">
                    {gettext('Payouts before your event happens')}
                </span>
            </li>
            <li>
                <CheckChunky
                    className="simple-pricing__checkmark"
                    aria-hidden="true"
                />
                <span className="simple-pricing__list__text">
                    {gettext('Detailed analytics and reporting')}
                </span>
            </li>
        </ul>
        <div className="simple-pricing__content">
            <h3>{gettext('Low fees for your attendees')}</h3>
            <ul className="simple-pricing__list--unstyled">
                <li>{gettext('No fees for free events')}</li>
                <li>{serviceFee}</li>
                {processingFee && <li>{processingFee}</li>}
            </ul>
            <p>
                {gettext(
                    'Fees are paid by ticket buyers, unless you choose to cover them.',
                )}
            </p>
            <a href="/support/articleredirect?anum=7285" target="_blank">
                {gettext('Find out more about how fees work')}
            </a>
        </div>
    </section>
);

export default SimplePricing;
