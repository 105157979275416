import { ANCHOR_LINK } from '@eventbrite/eds-nav-list-item';
import {
    LinkCreatorPropsInterface,
    NavigationItemPropsInterface,
} from '../../constants/interfaces';
import { trackHeaderMetric } from '../../tracking/header';

export const linkCreator = ({
    onClick,
    onMouseLeave,
    onMouseEnter,
    fallbackUrl = undefined,
    type = ANCHOR_LINK,
    shouldShow = false,
    collapseOnNarrow = false,
    ...props
}: LinkCreatorPropsInterface) => {
    const linkItem: NavigationItemPropsInterface = {
        type,
        shouldShow,
        fallbackUrl,
        collapseOnNarrow,
        ...props,
    };

    if (onMouseLeave) {
        linkItem.onMouseLeave = trackHeaderMetric(onMouseLeave);
    }

    if (typeof onClick === 'object') {
        linkItem.onClick = trackHeaderMetric(onClick);
    } else if (typeof onClick === 'function') {
        linkItem.onClick = onClick;
    }
    if (onMouseEnter) {
        linkItem.onMouseEnter = trackHeaderMetric(onMouseEnter);
    }

    return linkItem;
};
