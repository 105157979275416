import { getSigninOrSignupUrl } from '@eventbrite/global-nav';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { v4 as UUID } from 'uuid';
import CTAButton from '../CTA-Button';
import Link from '../Link';
import Logo from '../Logo';
import {
    contactLink,
    headerLinks,
    organizerOverviewLink,
} from '../utils/categoryLinks';
import { OrganizerSubNavPropsInterface } from '../utils/interfaces';
import './OrganizerSubNav.scss';

const OrganizerSubNav = ({ env, sticky }: OrganizerSubNavPropsInterface) => {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        if (window?.scrollY >= 10) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        if (sticky) {
            window?.addEventListener('scroll', handleScroll);
        }
    });
    const organizerSubNavClasses =
        sticky && scrolled
            ? classNames('organizer-sub-nav', 'sticky-sub-nav')
            : 'organizer-sub-nav';
    const organizerLink = organizerOverviewLink(env.serverUrl);
    return (
        <nav
            aria-label={gettext('Secondary Organizer').toString()}
            className={organizerSubNavClasses}
            data-testid="subnav"
        >
            <div className="organizer-sub-nav__left">
                <Logo content={organizerLink.content} url={organizerLink.url} />
                <ul className="organizer-sub-nav__left__links">
                    {headerLinks(env.serverUrl, env.localeInfo.tld).map(
                        (link) => (
                            <Link
                                content={link.content}
                                navItems={link.navItems}
                                url={link.url}
                                key={UUID()}
                                name={link.name}
                            />
                        ),
                    )}
                </ul>
            </div>
            <div className="organizer-sub-nav__right">
                {contactLink(env.serverUrl).map((link) => (
                    <Link
                        content={link.content}
                        navItems={link.navItems}
                        url={link.url}
                        key={UUID()}
                        name={link.name}
                    />
                ))}
                <CTAButton
                    cta={gettext('get started')}
                    url={getSigninOrSignupUrl(env.signupUrl)}
                />
            </div>
        </nav>
    );
};

export default OrganizerSubNav;
