import { useCallback, useMemo, useState } from 'react';
import { NavigationItemPropsInterface } from '../../constants/interfaces';

export const useNavState = (
    navigationItems: NavigationItemPropsInterface[],
) => {
    const initialNavStates = useMemo(
        () =>
            navigationItems.map(() => ({
                shouldIgnoreOnClickOutside: false,
            })),
        [navigationItems],
    );

    const [navStates, setNavStates] = useState(initialNavStates);

    const updateNavState = useCallback(
        (index: number) =>
            setNavStates(() => {
                const newArray = [...initialNavStates];
                newArray[index] = {
                    ...newArray[index],
                    shouldIgnoreOnClickOutside: true,
                };
                return newArray;
            }),
        [initialNavStates],
    );
    const removeOnClickOutsideClass = () => {
        // This removes the ignore-react-onclickoutside class from the dropdown links
        Array.from(
            globalThis.document
                ? document.querySelectorAll(
                      '.mlp-header__menus a.eds-dropdown-menu__link.ignore-react-onclickoutside',
                  )
                : [],
        ).forEach((link) =>
            link.classList.remove('ignore-react-onclickoutside'),
        );
    };

    const getNavStateProps = useCallback(
        (index: number) => ({
            navState: navStates[index],
            updateNavState: () => updateNavState(index),
            removeOnClickOutsideClass,
        }),
        [navStates, updateNavState],
    );

    return getNavStateProps;
};
