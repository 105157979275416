// Vendor
import { AvatarProps } from '@eventbrite/eds-avatar';
import { GenericLazyString } from '@eventbrite/i18n';
import { NavigationItemPropsInterface } from '../constants/interfaces';
// Internal=
export interface LinkPropsInterface {
    // if type is ROUTER_LINK `path` must be an absolute path
    path: string;
    content: string | GenericLazyString;
    narrowIconType?: JSX.Element;
    narrowIconColor?: string;
    isActive?: boolean;
    // if type is ROUTER_LINK we use react-router's Link
    type?: 'routerLink' | 'anchor';
    onClick?: () => {};
}

export interface TextItemPropInterface {
    value: string | GenericLazyString;
    buttonType?: 'button' | 'submit' | 'link';
    content?: JSX.Element | GenericLazyString | string;
    iconType?: JSX.Element;
    imageAlt?: string | Function;
    imageUrl?: string;
    isSquareImage?: boolean;
    path?: string;
    renderContainer?: Function;
    secondaryContent?: string | Function;
    showDivider?: boolean;
    dividerVertSpacing?: number;
    tertiaryContent?: string | Function;
}

export interface ItemPropInterface {
    type: string;
    content?: JSX.Element | GenericLazyString | string;
    secondaryContent?: string | JSX.Element;
    path?: string;
    active?: boolean;
    isDisable?: boolean;
    iconType?: JSX.Element;
    onActivate?: Function;
}

export interface DropdownMenuInterface {
    label: string | GenericLazyString;
    fallbackUrl: string;
    iconType: JSX.Element;
    iconColor: string;
    avatarProps?: AvatarProps;
    imageUrl?: string;
    narrowIconType: JSX.Element;
    narrowIconColor: string;
    textItems?: Array<TextItemPropInterface>;
    navItems?: Array<ItemPropInterface>;
    className?: string;
    preventMouseOver?: boolean;
    linkItem?: NavigationItemPropsInterface;
}

export type TOnAdditionalMenuTextItemSelect = (
    value: string | GenericLazyString,
    index: number,
) => void;

export enum BoxColor {
    slimeGreen = 'slime-green',
    uiGreen = 'ui-green',
    indigoBlue = 'indigo-blue',
    rustOrange = 'rust-orange',
    regBrown = 'reg-brown',
    navyBlue = 'navy-blue',
}

export interface DropdownItemProps {
    title: GenericLazyString;
    iconType?: JSX.Element;
    boxColor?:
        | 'slime-green'
        | 'ui-green'
        | 'indigo-blue'
        | 'rust-orange'
        | 'reg-brown'
        | 'navy-blue';
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export interface textDropdownItemProps {
    title: GenericLazyString;
    url?: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export interface CategoryDropdownProps {
    items: Array<DropdownItemProps>;
}

export interface NavItemProps {
    categoryTitle: GenericLazyString;
    iconDropdownInfo?: CategoryDropdownProps;
    textDropdownInfo?: textDropdownItemProps;
}

export interface MlpHeaderProps {
    navigationItems?: Array<NavItemProps>;
}
