import { BreadCrumbItem, BreadCrumbs } from '@eventbrite/breadcrumbs-infra';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import './WagtailModulePagesBreadCrumb.scss';

export interface WagtailModulePagesBreadCrumbItem {
    title: string;
    [key: string]: any;
}

export interface WagtailModulePagesBreadcrumbProps {
    breadcrumbs: WagtailModulePagesBreadCrumbItem[];
    containerClasses?: string;
    navClasses?: string;
}

export const WagtailModulePagesBreadCrumb: React.FunctionComponent<
    WagtailModulePagesBreadcrumbProps
> = (props) => {
    const breadcrumbItems = React.useMemo<BreadCrumbItem<{}>[]>(() => {
        const items: BreadCrumbItem<{}>[] = props.breadcrumbs.map((value) => {
            let url;
            if (Object.prototype.hasOwnProperty.call(value, 'url')) {
                url = value.url;
            } else {
                url = value.relative_url;
            }
            return {
                value: url,
                display: value.title,
            };
        });
        return items;
    }, [props]);

    return (
        <BreadCrumbs
            items={breadcrumbItems}
            separator={<span className="wagtail-breadcrumb__separator">/</span>}
            navClasses={`eds-text-bm breadcrumbs ${props.navClasses}`}
            containerClasses={props.containerClasses}
            ariaLabel={gettext('breadcrumbs')}
        >
            {(item) => (
                <a href={item.value} title={item.display.toString()}>
                    {item.display}
                </a>
            )}
        </BreadCrumbs>
    );
};
