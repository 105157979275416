import * as React from 'react';

const MlpMusicSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M1.532 10.52l7.562 7.386M5 5l8 8.047M10.558 1.57l7.29 7.446M15.966 20.277l-4.916 4.915a3 3 0 01-4.242-4.242L20.95 6.808a3 3 0 014.242 4.242l-4.94 4.94"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.908 5.884A11.486 11.486 0 0012.5 1C6.149 1 1 6.149 1 12.5c0 3.96 2 7.451 5.047 9.52M40 40l7 7M13 24l20.01 17 8.08-8L24 13M34.615 39.482a3.5 3.5 0 104.938-4.817M25 21l2 2"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

MlpMusicSvg.displayName = 'MlpMusicSvg';
export default MlpMusicSvg;
