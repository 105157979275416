import * as React from 'react';
import { ESizes } from '../types';
import { useMediaQuery } from './useMediaQuery';

const PixelRem = 1 / 16;

interface IBreakPointOptions {
    gap: number;
    columnGap?: number;
    rowGap?: number;
    columnCount: number;
}

type IBreakPoints = Partial<Record<ESizes, Partial<IBreakPointOptions>>>;

interface IGridBaseProps {
    component?: string;
    base: IBreakPointOptions;
    [key: string | number]: any;
}

export type IGridProps = IGridBaseProps & IBreakPoints;

export const generateGridStyles = ({
    gap,
    columnGap,
    rowGap,
}: IBreakPointOptions) => ({
    display: 'flex',
    columnGap: `${
        (columnGap || columnGap === 0 ? columnGap : gap) * PixelRem
    }rem`,
    rowGap: `${(rowGap || rowGap === 0 ? rowGap : gap) * PixelRem}rem`,
    flexFlow: 'row wrap',
});

const wrapChild = (child: any, flex: string) =>
    !!child && child !== 0 ? <div style={{ flex }}>{child}</div> : child;

export const Grid: React.FC<IGridProps> = ({
    children,
    component,
    base,
    ...props
}) => {
    const activeSize = useMediaQuery();
    const gridOptions = { ...base, ...(props[activeSize] || {}) };
    const Component = component as unknown as typeof React.Component;
    const flex = `1 1 calc(${100 / gridOptions.columnCount}% - ${
        gridOptions.gap * PixelRem
    }rem)`;

    const mappedChildren = React.Children.map(children, (child) => {
        return React.isValidElement(child)
            ? React.cloneElement(child, {
                  style: {
                      flex,
                  },
              } as React.Attributes)
            : wrapChild(child, flex);
    });

    const computedStyles = {
        ...generateGridStyles(gridOptions),
        ...(props.style || {}),
    };

    return component ? (
        <Component {...props} style={computedStyles}>
            {mappedChildren}
        </Component>
    ) : (
        <div {...props} style={computedStyles}>
            {mappedChildren}
        </div>
    );
};
