import { gettext } from '@eventbrite/i18n';
import { DropdownItemProps } from '../utils/interfaces';

const eventTicketingLink = (
    serverUrl: string,
    tld: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => {
    let path = 'l/sell-tickets/';
    if (tld === '.ca') {
        path = 'l/sell-tickets-online/';
    }
    return {
        title: gettext('Event Ticketing'),
        href: `${serverUrl}/${path}`,
        name: 'event-ticketing',
    };
};

const eventMarketingPlatformLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Event Marketing Platform'),
    href: `${serverUrl}/l/event-marketing-platform/`,
    name: 'event-marketing-platform',
});

const eventbriteAdsLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Eventbrite Ads'),
    href: `${serverUrl}/l/eventbrite-ads/`,
    name: 'eventbrite-ads',
});

const paymentsLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Payments'),
    href: `${serverUrl}/l/event-payment/`,
    name: 'payments',
});

export const solutionsLinkNavItems = (
    serverUrl: string,
    tld: string,
): Omit<DropdownItemProps, 'dropdownTheme'>[] => [
    eventTicketingLink(serverUrl, tld),
    eventMarketingPlatformLink(serverUrl),
    eventbriteAdsLink(serverUrl),
    paymentsLink(serverUrl),
];

const musicLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Music'),
    href: `${serverUrl}/l/music/`,
    name: 'music',
});

const foodBevLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Food & Beverage'),
    href: `${serverUrl}/l/food-drink-event-ticketing/`,
    name: 'food-and-beverage',
});

const performLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Performing Arts'),
    href: `${serverUrl}/l/performing-arts/`,
    name: 'performing-arts',
});

const charityLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Charity & Causes'),
    href: `${serverUrl}/l/npo/`,
    name: 'charity-and-causes',
});

const retailsLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Retail'),
    href: `${serverUrl}/l/host-retail-events/`,
    name: 'retail',
});

export const industryLinkNavItems = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'>[] => [
    musicLink(serverUrl),
    foodBevLink(serverUrl),
    performLink(serverUrl),
    charityLink(serverUrl),
    retailsLink(serverUrl),
];

const concertLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Concerts'),
    href: `${serverUrl}/l/music-venues/`,
    name: 'concerts',
});

const classesLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Classes & Workshops'),
    href: `${serverUrl}/l/create-a-workshop/`,
    name: 'classes-and-workshops',
});

const festivalLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Festivals & Fairs'),
    href: `${serverUrl}/l/festival-solutions/`,
    name: 'festivals-and-fairs',
});

const conferencesLink = (
    serverUrl: string,
    tld: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => {
    let path = 'l/conferences/';

    if (tld === '.co.uk') {
        path = 'l/conference-planning/';
    }

    return {
        title: gettext('Conferences'),
        href: `${serverUrl}/${path}`,
        name: 'conferences',
    };
};

const corporateLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Corporate Events'),
    href: `${serverUrl}/l/eventbrite-for-business/`,
    name: 'corporate-events',
});

const onlineLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Online Events'),
    href: `${serverUrl}/l/virtual-events-platform/`,
    name: 'online-events',
});

export const eventLinkNavItems = (
    serverUrl: string,
    tld: string,
): Omit<DropdownItemProps, 'dropdownTheme'>[] => [
    concertLink(serverUrl),
    classesLink(serverUrl),
    festivalLink(serverUrl),
    conferencesLink(serverUrl, tld),
    corporateLink(serverUrl),
    onlineLink(serverUrl),
];

const tipsLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Tips & Guides'),
    href: `${serverUrl}/blog/category/tips-and-guides/`,
    name: 'tips-and-guides',
});

const newsLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('News & Trends'),
    href: `${serverUrl}/blog/category/news-and-trends/`,
    name: 'news-and-trends',
});

const communityLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Community'),
    href: `${serverUrl}/blog/category/community/`,
    name: 'community',
});

const toolsLink = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'> => ({
    title: gettext('Tools & Features'),
    href: `${serverUrl}/blog/category/tools-and-features/`,
    name: 'tools-and-features',
});

export const blogLinkNavItems = (
    serverUrl: string,
): Omit<DropdownItemProps, 'dropdownTheme'>[] => [
    tipsLink(serverUrl),
    newsLink(serverUrl),
    communityLink(serverUrl),
    toolsLink(serverUrl),
];
