// TODO: Move this component to the new EDS project

import classNames from 'classnames';
import React, {
    DetailedHTMLProps,
    FunctionComponent,
    HTMLAttributes,
} from 'react';
import './Divider.scss';

export enum DividerDirection {
    horizontal = 'horizontal',
    vertical = 'vertical',
}

type DetailedHTMLHRElementProps = DetailedHTMLProps<
    HTMLAttributes<HTMLHRElement>,
    HTMLHRElement
>;
export interface DividerProps extends DetailedHTMLHRElementProps {
    direction?: DividerDirection;
}

/**
 * A reimplementation of EDS's Divider which supports custom CSS classes.
 *
 * @param props
 */
const Divider: FunctionComponent<DividerProps> = ({
    direction = DividerDirection.horizontal,
    className,
    style,
}) => (
    <hr
        style={style}
        className={classNames(className, 'pricing-divider', {
            [`pricing-divider--${direction}`]: direction,
        })}
        data-spec="divider-hr"
        aria-hidden="true"
    />
);

export default Divider;
