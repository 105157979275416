import * as React from 'react';

const MlpRetailSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M24 47s-2.015-7.955-2.015-11.955c0-6.848 3.818-8.34 6.906-18.045.902-2.837 1.804-4 3.085-4 3.637 0-1.364 12 0 18M41.302 25.835c.421 1.244.698 2.586.698 3.41 0 1.67-6 12.323-6 14.951V47"
            stroke="#200634"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.025 32.486l-10.13 3.562a3 3 0 01-3.825-1.834l-.005-.016-6.401-18.52a3 3 0 011.84-3.81L33.3 1.388a3 3 0 013.83 1.85l6.401 18.52a3 3 0 01-1.84 3.81l-9.873 3.473"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M16 27a2 2 0 11-4.001-.001A2 2 0 0116 27z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.045 8.612l6.922-2.431M2.622 18.596l22.413-7.873-22.413 7.873zM33.08 13.563l6.687-2.349M4.447 23.62l25.203-8.853L4.446 23.62z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

MlpRetailSvg.displayName = 'MlpRetailSvg';
export default MlpRetailSvg;
