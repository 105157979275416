import React from 'react';
import classNames from 'classnames';
import { gettext } from '@eventbrite/i18n';
import { Icon } from '@eventbrite/eds-icon';
import { TABBABLE_INDEX } from '@eventbrite/eds-hot-keys';

import { ChevronUpChunky } from '@eventbrite/eds-iconography';
import { ChevronDownChunky } from '@eventbrite/eds-iconography';

export default class DropLink extends React.PureComponent {
    componentDidUpdate() {
        const { tabIndex, shouldFocusDropLink } = this.props;

        return tabIndex === TABBABLE_INDEX && shouldFocusDropLink
            ? this._focus()
            : this._blur();
    }

    _getRef(ref) {
        this._link = ref;
        return this._link;
    }

    _blur() {
        this._link.blur();
    }

    _focus() {
        this._link.focus();
    }

    render() {
        const {
            children,
            fallbackUrl,
            dropUp,
            onClick,
            tabIndex,
            hideDropIconWhenNarrow,
        } = this.props;
        const iconType = dropUp ? <ChevronUpChunky /> : <ChevronDownChunky />;
        const iconClassName = classNames('eds-dropdown-menu__icon', {
            'eds-dropdown-menu__icon--narrow-breakpoints':
                hideDropIconWhenNarrow,
        });

        return (
            <a
                className="eds-dropdown-menu__link ignore-react-onclickoutside"
                href={fallbackUrl}
                onClick={onClick}
                data-spec="dropdown-menu-link"
                role="menuitem"
                ref={this._getRef.bind(this)}
                onMouseEnter={this._focus.bind(this)}
                onMouseLeave={this._blur.bind(this)}
                tabIndex={tabIndex}
                aria-label={gettext('Menu dropdown link').toString()}
            >
                <div
                    className="eds-dropdown-menu__contents"
                    data-spec="dropdown-menu-contents"
                >
                    {children}
                </div>
                <span className={iconClassName}>
                    <Icon
                        type={iconType}
                        color="grey-400"
                        isBlockLevel={true}
                    />
                </span>
            </a>
        );
    }
}
