// Vendor
import React from 'react';
// Internal
import { NavigationItemPropsInterface } from '../../constants/interfaces';
import { _getType } from './utils';

const UserAvatar = (props: Partial<NavigationItemPropsInterface>) => {
    const { avatarProps, imageUrl, iconType, narrowIconType } = props;

    let component = null;

    const UserAvatarComponent = _getType({
        avatarProps,
        imageUrl,
        iconType,
        narrowIconType,
    });

    if (UserAvatarComponent) {
        component = <UserAvatarComponent {...props} />;
    }

    return component;
};

export default UserAvatar;
