import React from 'react';
import {
    Actions,
    Components,
    Features,
    getHeapDataAttribute,
    Teams,
} from '../../../utils/heap-data';
import { ButtonProps } from '../utils/interfaces';
import './CTAButton.scss';

const Button = ({ cta, url }: ButtonProps) => {
    return (
        <a
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.OrganizerSubNav,
                component: Components.CTA,
                name: 'button',
                action: Actions.Click,
            })}
            href={url}
            className="cta-button"
        >
            {cta}
        </a>
    );
};

export default Button;
