// Vendor
import { Badge } from '@eventbrite/eds-badge';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
// Internal
import './DropdownTicketContent.scss';

interface IDropdownTicketContentProps {
    tickets: number;
}

const DropdownTicketContent = ({ tickets }: IDropdownTicketContentProps) => (
    <div>
        <span className="site-header__ticket-text">{gettext('Tickets')}</span>
        <Badge bgColor="ui-purple" count={tickets} />
    </div>
);

export default DropdownTicketContent;
