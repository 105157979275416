import { Icon } from '@eventbrite/eds-icon';
import classNames from 'classnames';
import React from 'react';
import {
    Actions,
    Components,
    Features,
    getHeapDataAttribute,
    Teams,
} from '../../../utils/heap-data';
import { DropdownItemProps, DropdownProps } from '../utils/interfaces';
import './Dropdown.scss';

export const DropdownItem = ({
    icon,
    title,
    href,
    dropdownTheme,
    name,
}: DropdownItemProps) => {
    const dropdownItemClasses = classNames('dropdown-item', {
        [`dropdown-item--${dropdownTheme}`]: dropdownTheme,
    });
    const dropdownIconClasses = classNames('dropdown-item__icon-container', {
        [`dropdown-item__icon-container--${dropdownTheme}`]: dropdownTheme,
    });

    const dropdownLinkClasses = classNames('dropdown-item__link', {
        [`dropdown-item__link--${dropdownTheme}`]: dropdownTheme,
    });

    return (
        <li
            key={title.toString()}
            className={dropdownItemClasses}
            data-testid="dropdown-item"
            tabIndex={0}
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.OrganizerSubNav,
                component: Components.DropdownItem,
                name: name ? name : '',
                action: Actions.Click,
            })}
        >
            <a href={href} className={dropdownLinkClasses} tabIndex={1}>
                {icon ? (
                    <span className={dropdownIconClasses}>
                        <Icon type={icon} />
                    </span>
                ) : null}
                <span className="dropdown-item__title">{title}</span>
            </a>
        </li>
    );
};

export const Dropdown = ({ dropdownItems, dropdownTheme }: DropdownProps) => {
    const dropdownClasses = classNames('dropdown', {
        [`dropdown--${dropdownTheme}`]: dropdownTheme,
    });

    return (
        <ul className={dropdownClasses} aria-label="submenu">
            {dropdownItems.map((props) => (
                <DropdownItem
                    {...props}
                    key={props.title.toString()}
                    dropdownTheme={dropdownTheme}
                />
            ))}
        </ul>
    );
};
