// Vendor
import { Icon } from '@eventbrite/eds-icon';
import { GenericLazyString } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
// Internal
import {
    DEFAULT_LOGO_COLOR,
    DEFAULT_LOGO_SIZE,
    DEFAULT_LOGO_TITLE,
    DEFAULT_LOGO_TYPE,
} from '../constants';
// Styles
import './Logo.scss';

export interface LogoPropsInterface {
    url: string;
    iconType?: JSX.Element;
    iconColor?: string;
    iconTitle?: GenericLazyString | string;
    iconWidth?: string;
    iconHeight?: string;
    backgroundColor?: string;
    shouldUseSpecialWidth?: boolean;
}

const Logo = (props: LogoPropsInterface) => {
    const {
        url,
        iconType = DEFAULT_LOGO_TYPE,
        iconColor = DEFAULT_LOGO_COLOR,
        iconTitle = DEFAULT_LOGO_TITLE,
        iconWidth = DEFAULT_LOGO_SIZE.width,
        iconHeight = DEFAULT_LOGO_SIZE.height,
        backgroundColor,
    } = props;

    const className = classNames('mlp-header__logo-link', {
        [`eds-bg-color--${backgroundColor}`]: backgroundColor,
    });

    return (
        <div className="mlp-header__logo">
            <a
                href={url}
                className={className}
                data-testid="mlp-header-logo-link"
            >
                <Icon
                    type={iconType}
                    color={iconColor}
                    title={iconTitle}
                    isBlockLevel={true}
                    height={iconHeight}
                    width={iconWidth}
                />
            </a>
        </div>
    );
};

export default Logo;
