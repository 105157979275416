import { gettext } from '@eventbrite/i18n';
import {
    getHeapDataAttribute,
    HeapAttributes,
} from '@eventbrite/marketing-tools-common';
import { NavigationItemPropsInterface } from '../../constants/interfaces';
import { LINK_TYPE } from '../../constants/linkItem';
import {
    adsLink,
    blogLink,
    charityAndCausesLink,
    classesAndWorkshopsLink,
    communityLink,
    concertsLink,
    conferencesLink,
    contactSalesLink,
    corporateEventsLink,
    createEventsLink,
    eventMarketingPlatform,
    eventTicketing,
    festivalAndFairsLink,
    foodAndBeverageLink,
    musicLink,
    onlineEventsLink,
    organizerResourceHubLink,
    paymentsLink,
    performingAndVisualArtsLink,
    retailLink,
    successStoriesLink,
    supportLink,
} from './dropdownLinks';
import { linkCreator } from './linkCreator';

const { Teams, Features, Components, Actions } = HeapAttributes;

export const getSolutionsNavItems = (tld: string, serverUrl: string) => {
    const solutionsNavItems: Record<string, NavigationItemPropsInterface[]> = {
        // any tld not added to this dictionary will default to this set of links
        default: [
            eventTicketing(tld, serverUrl),
            eventMarketingPlatform(serverUrl),
            adsLink(serverUrl),
            paymentsLink(serverUrl),
        ],
    };
    return solutionsNavItems[tld] || solutionsNavItems['default'];
};

export const solutionsLink = (
    tld: string,
    serverUrl: string,
): NavigationItemPropsInterface =>
    linkCreator({
        content: gettext('Solutions'),
        onMouseEnter: {
            label: 'GA_LABEL_SOLUTIONS',
            action: 'GA_ACTION_SOLUTIONS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_SOLUTIONS',
            action: 'GA_ACTION_SOLUTIONS_CLICK',
        },
        navItems: getSolutionsNavItems(tld, serverUrl),
        mergeOnMobile: true,
        collapseOnNarrow: true,
    });

export const getIndustryNavItems = (tld: string, serverUrl: string) => {
    const industryNavItems: Record<string, NavigationItemPropsInterface[]> = {
        // any tld not added to this dictionary will default to this set of links
        default: [
            musicLink(serverUrl),
            foodAndBeverageLink(serverUrl),
            performingAndVisualArtsLink(serverUrl),
            charityAndCausesLink(serverUrl),
            retailLink(serverUrl),
        ],
    };
    return industryNavItems[tld] || industryNavItems['default'];
};

export const industryLink = (
    tld: string,
    serverUrl: string,
): NavigationItemPropsInterface =>
    linkCreator({
        content: gettext('Industry'),
        onMouseEnter: {
            label: 'GA_LABEL_INDUSTRY',
            action: 'GA_ACTION_INDUSTRY_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_INDUSTRY',
            action: 'GA_ACTION_INDUSTRY_CLICK',
        },
        shouldShow: false,
        navItems: getIndustryNavItems(tld, serverUrl),
        mergeOnMobile: true,
        collapseOnNarrow: true,
    });

export const getEventTypesNavItems = (tld: string, serverUrl: string) => {
    const eventTypesNavItems: Record<string, NavigationItemPropsInterface[]> = {
        // any tld not added to this dictionary will default to this set of links
        default: [
            concertsLink(serverUrl),
            classesAndWorkshopsLink(serverUrl),
            festivalAndFairsLink(serverUrl),
            conferencesLink(tld, serverUrl),
            corporateEventsLink(serverUrl),
            onlineEventsLink(serverUrl),
        ],
        '.com.au': [
            concertsLink(serverUrl),
            classesAndWorkshopsLink(serverUrl),
            conferencesLink(tld, serverUrl),
            corporateEventsLink(serverUrl),
            onlineEventsLink(serverUrl),
        ],
        '.ca': [
            concertsLink(serverUrl),
            classesAndWorkshopsLink(serverUrl),
            conferencesLink(tld, serverUrl),
            corporateEventsLink(serverUrl),
            onlineEventsLink(serverUrl),
        ],
        '.ie': [
            concertsLink(serverUrl),
            classesAndWorkshopsLink(serverUrl),
            conferencesLink(tld, serverUrl),
            corporateEventsLink(serverUrl),
            onlineEventsLink(serverUrl),
        ],
        '.co.uk': [
            concertsLink(serverUrl),
            classesAndWorkshopsLink(serverUrl),
            conferencesLink(tld, serverUrl),
            corporateEventsLink(serverUrl),
            onlineEventsLink(serverUrl),
        ],
        '.co.nz': [
            concertsLink(serverUrl),
            classesAndWorkshopsLink(serverUrl),
            conferencesLink(tld, serverUrl),
            corporateEventsLink(serverUrl),
            onlineEventsLink(serverUrl),
        ],
    };
    return eventTypesNavItems[tld] || eventTypesNavItems['default'];
};

export const eventTypesLink = (
    tld: string,
    serverUrl: string,
): NavigationItemPropsInterface =>
    linkCreator({
        content: gettext('Event Types'),
        onMouseEnter: {
            label: 'GA_LABEL_EVENT_TYPES',
            action: 'GA_ACTION_EVENT_TYPES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_EVENT_TYPES',
            action: 'GA_ACTION_EVENT_TYPES_CLICK',
        },
        shouldShow: false,
        navItems: getEventTypesNavItems(tld, serverUrl),
        mergeOnMobile: true,
        collapseOnNarrow: true,
    });

export const getResourcesNavItems = (tld: string, serverUrl: string) => {
    const resourcesNavItems: Record<string, NavigationItemPropsInterface[]> = {
        // any tld not added to this dictionary will default to this set of links
        default: [
            createEventsLink(serverUrl),
            successStoriesLink(serverUrl),
            communityLink(serverUrl),
            blogLink(serverUrl),
            contactSalesLink(tld, serverUrl),
            supportLink(serverUrl),
        ],
        '.com': [
            createEventsLink(serverUrl),
            organizerResourceHubLink(serverUrl),
            successStoriesLink(serverUrl),
            communityLink(serverUrl),
            blogLink(serverUrl),
            contactSalesLink(tld, serverUrl),
            supportLink(serverUrl),
        ],
    };
    return resourcesNavItems[tld] || resourcesNavItems['default'];
};

export const resourcesLink = (
    tld: string,
    serverUrl: string,
): NavigationItemPropsInterface =>
    linkCreator({
        content: gettext('Resources'),
        onMouseEnter: {
            label: 'GA_LABEL_RESOURCES',
            action: 'GA_ACTION_RESOURCES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_RESOURCES',
            action: 'GA_ACTION_RESOURCES_CLICK',
        },
        shouldShow: false,
        navItems: getResourcesNavItems(tld, serverUrl),
        mergeOnMobile: true,
        collapseOnNarrow: true,
    });

export const pricingLink = (serverUrl: string) =>
    linkCreator({
        content: gettext('Pricing'),
        path: `${serverUrl}/organizer/pricing/`,
        onMouseEnter: {
            label: 'GA_LABEL_PRICING',
            action: 'GA_ACTION_PRICING_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_PRICING',
            action: 'GA_ACTION_PRICING_CLICK',
        },
        collapseOnNarrow: true,
    });

export const demoLink = (toggleDemo: Function) =>
    linkCreator({
        linkType: LINK_TYPE.LINK_BLUE,
        content: gettext('Demo'),
        collapseOnNarrow: true,
        onClick: () => {
            toggleDemo((prev: boolean) => !prev);
        },
        dataAnalyticsId: getHeapDataAttribute({
            team: Teams.CAT,
            feature: Features.MLPDemoExperience,
            component: Components.MLPHeaderDemoCTA,
            action: Actions.Click,
        }),
    });

export const logInLink = (loginPath: string) =>
    linkCreator({
        content: gettext('Log In'),
        path: loginPath,
        onMouseEnter: {
            label: 'GA_LABEL_LOGIN',
            action: 'GA_ACTION_LOGIN_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_LOGIN',
            action: 'GA_ACTION_LOGIN_CLICK',
        },
        collapseOnNarrow: true,
    });

export const signUpLink = (signupPath: string) =>
    linkCreator({
        content: gettext('Sign up free'),
        path: signupPath,
        linkType: LINK_TYPE.PILL,
        onMouseEnter: {
            label: 'GA_LABEL_SIGNUP',
            action: 'GA_ACTION_SIGNUP_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_SIGNUP',
            action: 'GA_ACTION_SIGNUP_CLICK',
        },
        collapseOnNarrow: true,
    });
