import * as React from 'react';

const ExitSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="exit_svg__eds-icon--exit_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="exit_svg__eds-icon--exit_base"
            d="M11 8V2H2v20h9v-6h-1v5H3V3h7v5z"
        />
        <path
            id="exit_svg__eds-icon--exit_arrow"
            d="M21.8 11.8L16.2 6l-.8.7 4.7 4.8H6v1h14.1l-4.7 4.8.7.7 5.9-6z"
        />
    </svg>
);

ExitSvg.displayName = 'ExitSvg';
export default ExitSvg;
