export enum Teams {
    Boom = 'email_campaigns',
    Mag = 'psa_and_growth',
    CAT = 'creator_acquisition',
    Marketing = 'marketing',
}

export enum Features {
    Subscribers = 'subscribers',
    ManageSubscribers = 'manage_subscribers',
    TaskList = 'task_list',
    PopularTools = 'popular_tools',
    BoostTrialTourModal = 'boost_trial_tour_modal',
    BoostStartTrial = 'boost_start_trial',
    PayAsYouGo = 'pay_as_you_go',
    MLPDemoExperience = 'mlp_demo_experience',
    PostPublish = 'post_publish',
    BoostTrialReactivation = 'boost_trial_reactivation',
    PricingAndPackaging = 'pricing_and_packaging',
    OpenAI = 'open_ai',
    PricingPage = 'pricing_page',
}

export enum Components {
    ImportList = 'import_a_list',
    NewList = 'new_list',
    PreviousEvents = 'previous_events',
    UploadCSV = 'upload_csv',
    ManualAdd = 'manual_add',
    ManualAddSingle = 'manual_add_single',
    ManualAddMultiple = 'manual_add_multiple',
    CreateEventInvite = 'create_event_invite',
    FacebookAdCampaign = 'facebook_ad_campaign',
    VisitEventbriteAcademy = 'visit_eventbrite_academy',
    SendEmailCampaign = 'email_campaign',
    CompleteProfile = 'complete_profile',
    EmailToolCard = 'email_tool_card',
    PostToSocialToolCard = 'post_to_social_tool_card',
    PSAToolCard = 'paid_social_ads_tool_card',
    EBAdsToolCard = 'eb_ads_tool_card',
    TourIntro = 'tour_modal_intro_next_button',
    TourEmail = 'tour_modal_email_next_button',
    TourSocial = 'tour_modal_social_next_button',
    TourPaidSocial = 'tour_modal_paid_social_next_button',
    TourEBAds = 'tour_modal_eb_ads_next_button',
    TourPromos = 'tour_modal_promos_next_button',
    TourComplete = 'tour_modal_complete_button',
    TourModalStep2LeftArrowButton = 'tour_modal_step2_left_arrow_button',
    TourModalStep3LeftArrowButton = 'tour_modal_step3_left_arrow_button',
    TourModalStep4LeftArrowButton = 'tour_modal_step4_left_arrow_button',
    TourModalStep5LeftArrowButton = 'tour_modal_step5_left_arrow_button',
    TourModalStep6LeftArrowButton = 'tour_modal_step6_left_arrow_button',
    TourModalStep1RightArrowButton = 'tour_modal_step1_right_arrow_button',
    TourModalStep2RightArrowButton = 'tour_modal_step2_right_arrow_button',
    TourModalStep3RightArrowButton = 'tour_modal_step3_right_arrow_button',
    TourModalStep4RightArrowButton = 'tour_modal_step4_right_arrow_button',
    TourModalStep5RightArrowButton = 'tour_modal_step5_right_arrow_button',
    TourModalStepperDot1 = 'tour_modal_stepper_dot_1',
    TourModalStepperDot2 = 'tour_modal_stepper_dot_2',
    TourModalStepperDot3 = 'tour_modal_stepper_dot_3',
    TourModalStepperDot4 = 'tour_modal_stepper_dot_4',
    TourModalStepperDot5 = 'tour_modal_stepper_dot_5',
    TourModalStepperDot6 = 'tour_modal_stepper_dot_6',
    EmailMarketingButton = 'email_marketing_button',
    SocialPostingButton = 'social_posting_button',
    FacebookEventsButton = 'facebook_events_button',
    PaidAdsButton = 'paid_ads_button',
    AdCampaignsButton = 'ad_campaigns_button',
    AudienceTargetingButton = 'audience_targeting_button',
    NotSureButton = 'not_sure_button',
    PurchaseSendsButton = 'purchase_sends_button',
    ComparePlansButton = 'compare_plans_button',
    DeclineButton = 'decline_button',
    CancelButton = 'cancel_button',
    DemoModalThumbnail = 'demo_modal_thumbnail',
    DemoModalThumbnailPlayButton = 'demo_modal_thumbnail_play_button',
    DemoModalPrimaryAction = 'demo_modal_primary_action',
    DemoModalCloseButton = 'demo_modal_close_button',
    MLPHeaderDemoCTA = 'mlp_header_demo_cta',
    AddToFacebook = 'add_to_facebook',
    EbAds = 'eb_ads',
    AddToLinkedIn = 'add_to_linkedin',
    ShareOnSocial = 'share_on_social',
    Email = 'email',
    PSA = 'paid_social_ads',
    PSALite = 'paid_social_ads_lite',
    RemindMeLater = 'remind_me_later',
    InstagramGrowthCampaign = 'instagram_growth',
    MEA = 'multi_event_ads',
    TrialStartCTACard = 'trial_start_cta_card',
    MarketingHubCTACard = 'marketing_hub_cta_card',
    DeclineTrialReactivation = 'decline_trial_reactivation',
    ClaimTrialReactivation = 'claim_trial_reactivation',
    PlanManagementButton = 'plan_management_button',
    EmailCampaignsTooltip = 'email_campaigns_tooltip',
    EmailCampaignBody = 'email_campaign_body',
    FreePlanCardCTA = 'free_plan_card_cta',
    FlexPlanCardCTA = 'flex_plan_card_cta',
    ProPlanCardCTA = 'pro_plan_card_cta',
    PremiumPlanCardCTA = 'premium_plan_card_cta',
}

export enum Actions {
    Start = 'start',
    ChooseFile = 'choose_file',
    Submit = 'submit',
    Create = 'create',
    Manage = 'manage',
    Click = 'click',
    Subscribe = 'click_subscribe',
    Select = 'select',
    Deselect = 'deselect',
}
