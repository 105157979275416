import { CoreApplicationContext } from '@eventbrite/context-gen';
import { initializeSdk, logEvent } from '@eventbrite/statsig';
import { AppProps, StatsigInitializationOptions } from '../types';
import { PAGE_VIEW } from './constants';

export const SAMPLE_RATE = 100;

export const getUserTrackingIDs = (
    request: CoreApplicationContext['request'],
) => ({
    correlationId: request.correlation_id,
});

export const initializeStatsigSdk = async (
    options: StatsigInitializationOptions,
) => {
    await initializeSdk({
        ...options,
        sampleRate: SAMPLE_RATE,
        deactivateSdk: false,
    });
};

export const getMetadata = (app: AppProps) => ({
    userID: `${app.user?.id}`,
    userPublicID: `${app.user?.publicId}`,
    userActiveOrganizationId: `${app.user?.activeOrganization?.id}`,
    isAttendee: `${app.user?.isAttendee}`,
    orgHasEvents: `${app.user?.orgHasEvents}`,
    domain: `${app.env.ebDomain}`,
    isMobile: `${app.env.isMobile}`,
    correlationId: `${app.request.correlation_id}`,
    sessionId: `${app.request.session_id}`,
    path: `${app.request.path}`,
});

export const logMlpPageView = (pathname: string) => {
    logEvent(PAGE_VIEW, pathname);
};
