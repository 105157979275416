import { HAS_DOCUMENT } from '@eventbrite/eds-utils';
import Cookies from 'cookies-js';
import {
    GetNavigationsInterface,
    NavigationItemPropsInterface,
} from '../../constants/interfaces';
import {
    adminAvatarDropdown,
    cachedHeaderAvatarDropdown,
    creatorAvatarDropdown,
} from './avatarDropdowns';
import {
    eventTypesLink,
    industryLink,
    logInLink,
    pricingLink,
    resourcesLink,
    signUpLink,
    solutionsLink,
} from './categoryLinks';

const getNavigationLinks = ({
    env,
    user,
}: GetNavigationsInterface): NavigationItemPropsInterface[] => {
    const navLinks = [
        solutionsLink(env?.localeInfo?.tld ?? '', env?.serverUrl ?? ''),
        industryLink(env?.localeInfo?.tld ?? '', env?.serverUrl ?? ''),
        eventTypesLink(env?.localeInfo?.tld ?? '', env?.serverUrl ?? ''),
        resourcesLink(env?.localeInfo?.tld ?? '', env?.serverUrl ?? ''),
        pricingLink(env?.serverUrl ?? ''),
    ];

    if (user?.isAuthenticated && user?.isStaff) {
        navLinks.push(adminAvatarDropdown({ env, user }));
    } else if (user?.isAuthenticated) {
        navLinks.push(creatorAvatarDropdown({ env, user }));
    } else if (HAS_DOCUMENT && Cookies.get('mglogin2')) {
        // The mglogin2 cookie will be set when a user is logged in for both statically
        // rendered pages and non-statically rendered pages. However, statically rendered
        // pages will not have an authenticated user object. Therefore, place this check after
        // the user checks to only show the cached header on statically rendered pages
        navLinks.push(cachedHeaderAvatarDropdown({ env }));
    } else {
        navLinks.push(
            logInLink(env?.signinUrl ?? ''),
            signUpLink(env?.signupUrl ?? ''),
        );
    }

    return navLinks;
};

export default getNavigationLinks;
