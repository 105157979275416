// Vendor
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    NavigationItemPropsInterface,
    UseNavigationInterface,
} from '../../constants/interfaces';
import getNavigationLinks from '../../utils/getNavigationLinks';

//TODO: consider using it from @eventbrite/feature-detection
export const useIsMobile = () => {
    const [windowWidth, setWindowWidth] = useState<number | null>(null);
    const MOBILE_BREAKPOINT = 792;

    const updateDimensions = useCallback(() => {
        setWindowWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    const isMobile = (windowWidth || 0) <= MOBILE_BREAKPOINT;
    return isMobile;
};

export const useNavigation = ({ user, env }: UseNavigationInterface) => {
    const navigation: NavigationItemPropsInterface[] = useMemo(
        () =>
            getNavigationLinks({
                user,
                env,
            }),
        [user, env],
    );

    return navigation;
};
