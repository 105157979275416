// Vendor
import { Icon } from '@eventbrite/eds-icon';
import React from 'react';
// Styles
import './MenuIcon.scss';

export interface IMenuIconProps {
    iconType: JSX.Element;
    iconColor?: string;
}

const MenuIcon = ({ iconType, iconColor = 'grey-700' }: IMenuIconProps) => (
    <Icon
        type={iconType}
        size="small"
        color={iconColor}
        data-testid="global-header-menu-icon"
    />
);

export default MenuIcon;
