// GA CATEGORIES
export const GA_CATEGORY_MLP_HEADER = 'mlp-header';

// GA ACTIONS
export const GA_ACTION_HOST_AN_EVENT_CLICK = 'HostAnEventLinkClick';
export const GA_ACTION_SOLUTIONS_CLICK = 'SolutionLinkClick';
export const GA_ACTION_SOLUTIONS_HOVER = 'SolutionLinkHover';
export const GA_ACTION_INDUSTRY_HOVER = 'IndustryLinkHover';
export const GA_ACTION_INDUSTRY_CLICK = 'IndustryLinkClick';
export const GA_ACTION_EVENT_TYPES_CLICK = 'EventTypesLinkClick';
export const GA_ACTION_EVENT_TYPES_HOVER = 'EventTypesLinkHover';
export const GA_ACTION_RESOURCES_HOVER = 'ResourcesLinkHover';
export const GA_ACTION_RESOURCES_CLICK = 'ResourcesLinkClick';
export const GA_ACTION_PRICING_HOVER = 'PricingLinkHover';
export const GA_ACTION_PRICING_CLICK = 'PricingLinkClick';
export const GA_ACTION_LOGIN_HOVER = 'LoginLinkHover';
export const GA_ACTION_LOGIN_CLICK = 'LoginLinkClick';
export const GA_ACTION_SIGNUP_HOVER = 'SignupLinkHover';
export const GA_ACTION_SIGNUP_CLICK = 'SignupLinkClick';
export const GA_ACTION_EVENT_TICKETING_HOVER = 'EventTicketingLinkHover';
export const GA_ACTION_EVENT_TICKETING_CLICK = 'EventTicketingLinkClick';
export const GA_ACTION_EVENT_MARKETING_SUITE_HOVER =
    'EventMarketingSuiteLinkHover';
export const GA_ACTION_EVENT_MARKETING_SUITE_CLICK =
    'EventMarketingSuiteLinkClick';
export const GA_ACTION_PAYMENTS_HOVER = 'PaymentsLinkHover';
export const GA_ACTION_PAYMENTS_CLICK = 'PaymentsLinkClick';
export const GA_ACTION_WHY_EVENTBRITE_HOVER = 'WhyEventbriteLinkHover';
export const GA_ACTION_WHY_EVENTBRITE_CLICK = 'WhyEventbriteLinkClick';
export const GA_ACTION_COMMUNITY_HOVER = 'CommunityLinkHover';
export const GA_ACTION_COMMUNITY_CLICK = 'CommunityLinkClick';
export const GA_ACTION_SUCCESS_STORIES_HOVER = 'SuccessStoriesLinkHover';
export const GA_ACTION_SUCCESS_STORIES_CLICK = 'SuccessStoriesLinkClick';
export const GA_ACTION_BLOG_HOVER = 'BlogLinkHover';
export const GA_ACTION_BLOG_CLICK = 'BlogLinkClick';
export const GA_ACTION_CONTACT_SALES_HOVER = 'ContactSalesLinkHover';
export const GA_ACTION_CONTACT_SALES_CLICK = 'ContactSalesLinkClick';
export const GA_ACTION_SUPPORT_HOVER = 'SupportLinkHover';
export const GA_ACTION_SUPPORT_CLICK = 'SupportLinkClick';
export const GA_ACTION_CONCERTS_HOVER = 'ConcertsLinkHover';
export const GA_ACTION_CONCERTS_CLICK = 'ConcertsLinkClick';
export const GA_ACTION_CLASSES_AND_WORKSHOPS_HOVER =
    'ClassesAndWorkshopsLinkHover';
export const GA_ACTION_CLASSES_AND_WORKSHOPS_CLICK =
    'ClassesAndWorkshopsLinkClick';
export const GA_ACTION_FESTIVALS_AND_FAIRS_HOVER = 'FestivalsAndFairsLinkHover';
export const GA_ACTION_FESTIVALS_AND_FAIRS_CLICK = 'FestivalsAndFairsLinkClick';
export const GA_ACTION_NIGHT_LIFE_HOVER = 'NightLifeLinkHover';
export const GA_ACTION_NIGHT_LIFE_CLICK = 'NightLifeLinkClick';
export const GA_ACTION_CONFERENCES_HOVER = 'ConferencesLinkHover';
export const GA_ACTION_CONFERENCES_CLICK = 'ConferencesLinkClick';
export const GA_ACTION_CORPORATE_EVENTS_HOVER = 'CorporateEventsLinkHover';
export const GA_ACTION_CORPORATE_EVENTS_CLICK = 'CorporateEventsLinkClick';
export const GA_ACTION_ONLINE_EVENTS_HOVER = 'OnlineEventsLinkHover';
export const GA_ACTION_ONLINE_EVENTS_CLICK = 'OnlineEventsLinkClick';
export const GA_ACTION_MUSIC_HOVER = 'MusicLinkHover';
export const GA_ACTION_MUSIC_CLICK = 'MusicLinkClick';
export const GA_ACTION_FOOD_AND_BEVERAGE_HOVER = 'FoodAndBeverageLinkHover';
export const GA_ACTION_FOOD_AND_BEVERAGE_CLICK = 'FoodAndBeverageLinkClick';
export const GA_ACTION_PERFORMING_AND_VISUAL_ARTS_HOVER =
    'PerformingAndVisualArtsLinkHover';
export const GA_ACTION_PERFORMING_AND_VISUAL_ARTS_CLICK =
    'PerformingAndVisualArtsLinkClick';
export const GA_ACTION_PROFESSIONAL_SERVICES_HOVER =
    'ProfessionalServicesLinkHover';
export const GA_ACTION_PROFESSIONAL_SERVICES_CLICK =
    'ProfessionalServicesLinkClick';
export const GA_ACTION_CHARITY_AND_CAUSES_HOVER = 'CharityAndCausesLinkHover';
export const GA_ACTION_CHARITY_AND_CAUSES_CLICK = 'CharityAndCausesLinkClick';
export const GA_ACTION_HEALTH_AND_FITNESS_HOVER = 'HealthAndFitnessLinkHover';
export const GA_ACTION_HEALTH_AND_FITNESS_CLICK = 'HealthAndFitnessLinkClick';
export const GA_ACTION_RETAIL_HOVER = 'RetailLinkHover';
export const GA_ACTION_RETAIL_CLICK = 'RetailLinkClick';
export const GA_ACTION_MANAGE_EVENTS_HOVER = 'ManageEventsLinkHover';
export const GA_ACTION_MANAGE_EVENTS_CLICK = 'ManageEventsLinkClick';
export const GA_ACTION_ADMIN_HOVER = 'AdminLinkHover';
export const GA_ACTION_ADMIN_CLICK = 'AdminLinkClick';
export const GA_ACTION_TIKIBAR_HOVER = 'TikibarLinkHover';
export const GA_ACTION_TIKIBAR_CLICK = 'TikibarLinkClick';
export const GA_ACTION_FEATURE_FLAGS_HOVER = 'FeatureFlagsLinkHover';
export const GA_ACTION_FEATURE_FLAGS_CLICK = 'FeatureFlagsLinkClick';
export const GA_ACTION_ACCOUNT_SETTINGS_HOVER = 'AccountSettingsLinkHover';
export const GA_ACTION_ACCOUNT_SETTINGS_CLICK = 'AccountSettingsLinkClick';
export const GA_ACTION_COLLECTIONS_HOVER = 'CollectionsLinkHover';
export const GA_ACTION_COLLECTIONS_CLICK = 'CollectionsLinkClick';
export const GA_ACTION_CREDITS_HOVER = 'CreditsLinkHover';
export const GA_ACTION_CREDITS_CLICK = 'CreditsLinkClick';
export const GA_ACTION_FOLLOWING_HOVER = 'FollowingLinkHover';
export const GA_ACTION_FOLLOWING_CLICK = 'FollowingLinkClick';
export const GA_ACTION_INTERESTS_HOVER = 'InterestsLinkHover';
export const GA_ACTION_INTERESTS_CLICK = 'InterestsLinkClick';
export const GA_ACTION_LIKES_HOVER = 'LikesLinkHover';
export const GA_ACTION_LIKES_CLICK = 'LikesLinkClick';
export const GA_ACTION_LOGOUT_HOVER = 'LogoutLinkHover';
export const GA_ACTION_LOGOUT_CLICK = 'LogoutLinkClick';
export const GA_ACTION_TICKETS_HOVER = 'TicketsLinkHover';
export const GA_ACTION_TICKETS_CLICK = 'TicketsLinkClick';
export const GA_ACTION_BROWSE_EVENTS_HOVER = 'BrowseEventsLinkHover';
export const GA_ACTION_BROWSE_EVENTS_CLICK = 'BrowseEventsLinkClick';
export const GA_ACTION_PAYMENTS_SUITE_HOVER = 'PaymentsSuiteLinkHover';
export const GA_ACTION_PAYMENTS_SUITE_CLICK = 'PaymentsSuiteLinkClick';
export const GA_ACTION_FESTIVAL_AND_FAIRS_HOVER = 'FestivalAndFairsLinkHover';
export const GA_ACTION_FESTIVAL_AND_FAIRS_CLICK = 'FestivalAndFairsLinkClick';
export const GA_ACTION_ADS_HOVER = 'AdsLinkHover';
export const GA_ACTION_ADS_CLICK = 'AdsLinkClick';

// GA LABELS
export const GA_LABEL_SOLUTIONS = 'Solutions';
export const GA_LABEL_INDUSTRY = 'Industry';
export const GA_LABEL_EVENT_TYPES = 'EventTypes';
export const GA_LABEL_RESOURCES = 'Resources';
export const GA_LABEL_PRICING = 'Pricing';
export const GA_LABEL_LOGIN = 'Login';
export const GA_LABEL_SIGNUP = 'Signup';
export const GA_LABEL_EVENT_TICKETING = 'EventTicketing';
export const GA_LABEL_EVENT_MARKETING_SUITE = 'EventMarketingSuite';
export const GA_LABEL_PAYMENTS = 'Payments';
export const GA_LABEL_WHY_EVENTBRITE = 'WhyEventbrite';
export const GA_LABEL_COMMUNITY = 'Community';
export const GA_LABEL_SUCCESS_STORIES = 'SuccessStories';
export const GA_LABEL_BLOG = 'Blog';
export const GA_LABEL_CONTACT_SALES = 'ContactSales';
export const GA_LABEL_SUPPORT = 'Support';
export const GA_LABEL_CONCERTS = 'Concerts';
export const GA_LABEL_CLASSES_AND_WORKSHOPS = 'ClassesAndWorkshops';
export const GA_LABEL_FESTIVAL_AND_FAIRS = 'FestivalsAndFairs';
export const GA_LABEL_NIGHT_LIFE = 'NightLife';
export const GA_LABEL_CONFERENCES = 'Conferences';
export const GA_LABEL_CORPORATE_EVENTS = 'CorporateEvents';
export const GA_LABEL_ONLINE_EVENTS = 'OnlineEvents';
export const GA_LABEL_MUSIC = 'Music';
export const GA_LABEL_FOOD_AND_BEVERAGE = 'FoodAndBeverage';
export const GA_LABEL_PERFORMING_AND_VISUAL_ARTS = 'PeformingAndVisualArts';
export const GA_LABEL_PROFESSIONAL_SERVICES = 'ProfessionalServices';
export const GA_LABEL_CHARITY_AND_CAUSES = 'CharityAndCauses';
export const GA_LABEL_HEALTH_AND_FITNESS = 'HealthAndFitness';
export const GA_LABEL_RETAIL = 'Retail';
export const GA_LABEL_MANAGE_EVENTS = 'ManageEvents';
export const GA_LABEL_ADMIN = 'Admin';
export const GA_LABEL_TIKIBAR = 'Tikibar';
export const GA_LABEL_FEATURE_FLAGS = 'FeatureFlags';
export const GA_LABEL_ACCOUNT_SETTINGS = 'AccountSettings';
export const GA_LABEL_COLLECTIONS = 'Collections';
export const GA_LABEL_CREDITS = 'Credits';
export const GA_LABEL_FOLLOWING = 'Following';
export const GA_LABEL_INTERESTS = 'Interests';
export const GA_LABEL_LIKES = 'Likes';
export const GA_LABEL_LOGOUT = 'Logout';
export const GA_LABEL_TICKETS = 'Tickets';
export const GA_LABEL_BROWSE_EVENTS = 'BrowseEvents';
export const GA_LABEL_PAYMENTS_SUITE = 'PaymentsSuite';
export const GA_LABEL_ADS = 'Ads';
