import * as React from 'react';

interface IBlockProps {
    component?: string;
    [key: string | number]: any;
}

export const Block: React.FC<IBlockProps> = ({
    children,
    component,
    ...props
}) => {
    const Component = component || React.Fragment;
    return <Component {...props}>{children}</Component>;
};
