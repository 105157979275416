import React from 'react';
import { ThemeContextProvider } from '../utils/context/theme-context';
import { THEME_TYPES } from '../utils/types';
import { WagtailModulePagesBreadCrumbItem } from '../utils/WagtailModulePagesBreadCrumb';
import './ModuleList.scss';
import { getModuleComponent } from './modules';

interface ModuleProps {
    moduleType: string;
    moduleContext: object;
    env: any;
    user: any;
    breadcrumbs?: WagtailModulePagesBreadCrumbItem[];
    isContentFromContentful?: boolean;
}

const Module = ({
    moduleType,
    moduleContext,
    env,
    user,
    breadcrumbs,
    isContentFromContentful,
}: ModuleProps) => {
    const ModuleComponent = getModuleComponent(moduleType);
    return (
        <ModuleComponent
            env={env}
            user={user}
            breadcrumbs={breadcrumbs}
            {...moduleContext}
            isContentFromContentful={isContentFromContentful}
        />
    );
};

interface BaseModuleValue {
    moduleClass?: string;
    anchorTag?: string;
}

// The Module interface matches the schema for a module in Wagtail's API.
// The keys given are converted from snake_case to camelCase
// A Wagtail module is serialized as:
// {
//     type: string (e.g. 'raw_html_content')
//     value: object (e.g. {'rawHtmlBlock': '<div>hello world</div>'})
//     id: string (e.g. '76f18e00-dc22-4a0d-9280-25aefe98b005')
// }
export interface ModuleInterface {
    type: string;
    value: BaseModuleValue & Record<string, any>;
    id?: string;
    breadcrumbs?: WagtailModulePagesBreadCrumbItem[];
}

export interface ModuleListProps {
    modules: Array<ModuleInterface>;
    env?: any;
    user?: any;
    theme?: THEME_TYPES;
    breadcrumbs?: WagtailModulePagesBreadCrumbItem[];
    isContentFromContentful?: boolean;
}

class ModuleList extends React.PureComponent<ModuleListProps> {
    render() {
        const {
            modules,
            env,
            user,
            theme,
            breadcrumbs,
            isContentFromContentful = false,
        } = this.props;
        const filteredModules = modules.filter(
            ({ type }) => type != 'sticky_cta_content',
        );
        return (
            <div className="module-landing-page__container">
                <ThemeContextProvider theme={theme}>
                    {filteredModules.map(({ type, value }, idx) => (
                        <div
                            className="module-landing-page__module-container"
                            key={idx}
                        >
                            {value.anchorTag && <span id={value.anchorTag} />}
                            <Module
                                key={idx}
                                moduleType={type}
                                moduleContext={value}
                                env={env}
                                user={user}
                                breadcrumbs={
                                    idx === 0 ? breadcrumbs : undefined
                                }
                                isContentFromContentful={
                                    isContentFromContentful
                                }
                            />
                        </div>
                    ))}
                </ThemeContextProvider>
            </div>
        );
    }
}

export default ModuleList;
