import { AvatarProps, AvatarSize } from '@eventbrite/eds-avatar';
import { UserChunky as UserChunkySvg } from '@eventbrite/eds-iconography';
import React from 'react';
import { MENU_AVATAR_BACKGROUND, MENU_AVATAR_COLOR } from '../constants';

export const getAvatarProps = ({
    fullName,
    thumbnailUrl,
    backgroundColor,
    iconColor,
}: {
    fullName?: string;
    thumbnailUrl?: string;
    backgroundColor?: string;
    iconColor?: string;
}): AvatarProps => ({
    imageUrl: thumbnailUrl,
    text: fullName || '',
    backgroundColor:
        backgroundColor || (!thumbnailUrl && fullName)
            ? MENU_AVATAR_BACKGROUND
            : undefined,
    textColor: !thumbnailUrl && fullName ? MENU_AVATAR_COLOR : 'grey-700',
    iconType: !thumbnailUrl && !fullName ? <UserChunkySvg /> : undefined,
    iconColor: iconColor || 'grey-700',
    size: !thumbnailUrl && fullName ? AvatarSize.xsmall : AvatarSize.small,
});
