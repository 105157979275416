import * as React from 'react';

const MlpCharitySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            clipRule="evenodd"
            d="M31.648 19.09C33.527 16.377 34 14.285 34 13c0-2.913-1-5-4-5-2.88 0-2.995 1.843-5.654 1.99L24 10l.346-.01L24 10c-3 0-3.299-2-6-2s-4 2.002-4 5.114l.004.234c.091 2.532 1.692 7.202 9.903 13.385a48.734 48.734 0 003.235-2.66l4.506-4.983zM32 1c4.338 0 9 2.98 9 11 0 5.685-3.86 11.84-11.578 18.467.612.378 1.248.76 1.909 1.146L32 32c2.293 1.316 5.892 3.255 10.797 5.817L47 40l-5 2 2 5c-7.947-4.243-14.613-8.33-20-12.26C18.614 38.67 11.947 42.756 4 47l2-5-5-2 5.22-2.754c4.295-2.281 7.502-4.03 9.622-5.246a92.888 92.888 0 002.67-1.59C10.837 23.804 7 17.668 7 12c0-8.02 4.662-11 9-11 4.876 0 4.995 2.855 7.786 2.995L24 4c3.025 0 3.025-3 8-3z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M29.722 30.651c-2.312-1.415-4.292-2.773-5.986-4.068m-5.596 3.505a86.575 86.575 0 006.007 4.758l-6.007-4.758z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

MlpCharitySvg.displayName = 'MlpCharitySvg';
export default MlpCharitySvg;
