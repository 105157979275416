import classNames from 'classnames';
import { omit } from 'lodash';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import { HotKeys } from 'react-hotkeys';

import { ALIGN_LEFT, Dropdown } from '@eventbrite/eds-containers';
import { ITEMS_PROP_TYPE, NavList } from '@eventbrite/eds-nav-list';
import { TextList, TEXT_ITEMS_PROP_TYPE } from '@eventbrite/eds-text-list';
import { getAdditionalProps, isMediumDown } from '@eventbrite/eds-utils';
import DropLink from './DropLink';

import {
    HIDE_CHILD,
    SHOW_CHILD,
    TABBABLE_INDEX,
    UNTABBABLE_INDEX,
} from '@eventbrite/eds-hot-keys';
import NavigationLink from '../NavigationLink';
import { ACTION_KEY_MAP } from './hotKeys';

import { Icon } from '@eventbrite/eds-icon';
import {
    ChevronLeftChunky,
    ChevronRightChunky,
} from '@eventbrite/eds-iconography';
import { LINK_TYPE } from '../../constants/linkItem';
import { SUB_MENU_TITLE, MAIN_MENU } from '../constants';
import './dropdownMenu.scss';

const FOCUSED_POSITION = 0;
const UNFOCUSED_POSITION = -1;

// exported only for test
export const DropdownMenuList = ({
    show,
    navItems,
    textItems,
    align,
    dropUp,
    onHide,
    onTextItemSelect,
    defaultFocusPosition,
}) => {
    let menu = null;
    let list;

    if (textItems) {
        list = (
            <TextList
                items={textItems}
                onItemSelect={onTextItemSelect}
                defaultFocusPosition={defaultFocusPosition}
            />
        );
    } else if (navItems) {
        list = (
            <NavList
                items={navItems}
                defaultFocusPosition={defaultFocusPosition}
                hideDropdown={onHide}
            />
        );
    }

    if (show) {
        menu = (
            <Dropdown align={align} dropUp={dropUp} onClickOutside={onHide}>
                {list}
            </Dropdown>
        );
    }

    return menu;
};

class NavigationDropdownMenu extends React.PureComponent {
    static propTypes = {
        /**
         * The contents of link that activates the dropdown menu
         */
        children: PropTypes.node.isRequired,
        /**
         * The fallback URL for the link that activates the dropdown menu
         * (for a11y mostly)
         */
        fallbackUrl: PropTypes.string,
        /**
         * The text items to show in he dropdown menu
         */
        textItems: TEXT_ITEMS_PROP_TYPE,
        /**
         * The nav items to show in he dropdown menu
         */
        navItems: ITEMS_PROP_TYPE,
        /**
         * How the dropdown should be horizontally positioned relative to the link
         */
        dropdownAlign: Dropdown.propTypes.align,
        /**
         * Whether or not the dropdown should "drop up"
         */
        dropUp: Dropdown.propTypes.dropUp,
        /**
         * Whether or not to prevent the dropdown from showing on mouse over
         */
        preventMouseOver: PropTypes.bool,
        /**
         * Callback function that will be called when a list item is selected
         */
        onTextItemSelect: PropTypes.func,
        /**
         * Option to hide the chevron drop icon when narrow breakpoints are used
         */
        hideDropIconWhenNarrow: PropTypes.bool,
        /**
         * Option to add a function when the mouse hover the dropdown
         * Added in order to track when a user hover the dropdown
         */
        onMouseEnterDropdownMenu: PropTypes.func,
        /**
         * Option to add a function when the dropdown is clicked
         */
        onClickDropdown: PropTypes.func,
    };

    static defaultProps = {
        dropdownAlign: ALIGN_LEFT,
        dropUp: false,
        preventMouseOver: false,
        hideDropIconWhenNarrow: false,
    };

    state = {
        showDropdown: false,
        tabIndex: TABBABLE_INDEX,
        focusPosition: UNFOCUSED_POSITION,
        shouldFocusDropLink: false,
        subMenuItems: [],
    };

    hotKeyHandlers = {
        [HIDE_CHILD]: this._hideDropdown.bind(this, {
            shouldFocusDropLink: true,
        }),
        [SHOW_CHILD]: (e) =>
            this._showDropdown({ shouldFocusChild: true, isKeyEvent: true }, e),
    };

    _hideDropdown({ shouldFocusDropLink = false } = {}) {
        this.setState({
            showDropdown: false,
            tabIndex: TABBABLE_INDEX,
            focusPosition: UNFOCUSED_POSITION,
            shouldFocusDropLink,
            subMenuItems: [],
        });
    }

    _showDropdown(
        {
            shouldFocusChild = false,
            isKeyEvent = false,
            shouldFocusDropLink = false,
        } = {},
        e = false,
    ) {
        if (isKeyEvent) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            showDropdown: true,
            tabIndex: isKeyEvent ? UNTABBABLE_INDEX : TABBABLE_INDEX,
            focusPosition: shouldFocusChild
                ? FOCUSED_POSITION
                : UNFOCUSED_POSITION,
            shouldFocusDropLink,
        });
    }

    _handleLinkClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const { showDropdown } = this.state;
        const { onClickDropdown } = this.props;
        if (onClickDropdown) {
            onClickDropdown({ showingDropdown: !showDropdown });
        }
        if (showDropdown) {
            this._hideDropdown();
        } else {
            this._showDropdown();
        }
    }

    _handleMouseOver() {
        this._showDropdown({ shouldFocusDropLink: true });
    }

    _handleMouseLeave() {
        this._hideDropdown({ shouldFocusDropLink: false });
    }

    _handleTextItemSelect(value, index) {
        const { onTextItemSelect } = this.props;

        // NOTE: Need to always hide the dropdown when an item is selected
        // regardless of if an `onTextItemSelect` handler is specified
        this._hideDropdown();

        if (onTextItemSelect) {
            onTextItemSelect(value, index);
        }
    }

    _handleItemClick = (type, item, hideDropdown) => {
        if (type === MAIN_MENU) {
            let subMenuItems = [];

            if (item.navItems) {
                subMenuItems = [
                    { ...item, menuType: SUB_MENU_TITLE },
                    ...item.navItems,
                ];
            }
            this.setState({
                subMenuItems,
            });
        } else if (type === SUB_MENU_TITLE) {
            this.setState({
                subMenuItems: [],
            });
        }
    };

    _getMainMenuContent(item) {
        if ([LINK_TYPE.PILL, LINK_TYPE.LINK_BLUE].includes(item.linkType)) {
            return <NavigationLink {...item} collapseOnNarrow={true} />;
        } else if (item.navItems) {
            return (
                <>
                    {item.content}{' '}
                    <span className="eds-dropdown-menu__icon">
                        <Icon
                            size="small"
                            type={<ChevronRightChunky />}
                            isBlockLevel
                        />
                    </span>
                </>
            );
        }

        return item.content;
    }

    render() {
        const {
            children,
            fallbackUrl,
            navItems,
            textItems,
            dropdownAlign,
            dropUp,
            preventMouseOver,
            hideDropIconWhenNarrow,
            onMouseEnterDropdownMenu,
        } = this.props;
        const {
            showDropdown,
            tabIndex,
            focusPosition,
            shouldFocusDropLink,
            subMenuItems,
        } = this.state;
        const additionalProps = getAdditionalProps(this);
        const mainMenuItems = navItems.map((item) =>
            omit(
                {
                    ...item,
                    onClick: (event, hideDropdown) => {
                        this._handleItemClick(MAIN_MENU, item, hideDropdown);
                    },
                    content: this._getMainMenuContent(item),
                    showSymbols: true,
                },
                ['navItems', 'linkType'],
            ),
        );

        const subMenuItemsWithHandleClicks = subMenuItems.map((item) =>
            omit(
                {
                    ...item,
                    onClick: (event, hideDropdown) => {
                        if (item.menuType === SUB_MENU_TITLE) {
                            this._handleItemClick(
                                SUB_MENU_TITLE,
                                item,
                                hideDropdown,
                            );
                        }
                    },
                    iconType: item.menuType === SUB_MENU_TITLE && (
                        <ChevronLeftChunky />
                    ),
                    content: (
                        <span
                            className={
                                item.menuType === SUB_MENU_TITLE
                                    ? 'nav-dropdown-menu--item'
                                    : 'nav-dropdown-menu--sub-item'
                            }
                        >
                            {item.content}
                        </span>
                    ),
                },
                [
                    'shouldShow',
                    'mergeOnMobile',
                    'linkType',
                    'collapseOnNarrow',
                    'fallbackUrl',
                    'navItems',
                    'menuType',
                ],
            ),
        );

        const { className = '' } = additionalProps;
        const classes = classNames(
            'eds-dropdown-menu',
            'mlp-dropdown-menu-submenu',
            {
                'eds-dropdown-menu--active': showDropdown,
            },
            className,
        );
        let _handleMouseOver;
        let _handleMouseLeave;

        // maintain the hover-ability on desktop but there is no concept of hover on mobile
        // if the component mounts on a mobile/tablet sized window, we assume no hover
        if (!isMediumDown() && !preventMouseOver) {
            _handleMouseOver = this._handleMouseOver.bind(this);
            _handleMouseLeave = this._handleMouseLeave.bind(this);
        }

        return (
            <HotKeys keyMap={ACTION_KEY_MAP} handlers={this.hotKeyHandlers}>
                <div
                    data-spec="dropdown-menu"
                    role="menubar"
                    tabIndex="-1"
                    {...additionalProps}
                    className={classes}
                    onMouseOver={_handleMouseOver}
                    onMouseLeave={_handleMouseLeave}
                    onFocus={noop()}
                    onMouseEnter={onMouseEnterDropdownMenu}
                >
                    <DropLink
                        fallbackUrl={fallbackUrl}
                        dropUp={dropUp}
                        onClick={this._handleLinkClick.bind(this)}
                        tabIndex={tabIndex}
                        preventMouseOver={preventMouseOver}
                        showDropdown={showDropdown}
                        hideDropIconWhenNarrow={hideDropIconWhenNarrow}
                        shouldFocusDropLink={shouldFocusDropLink}
                    >
                        {children}
                    </DropLink>
                    {!subMenuItems.length && (
                        <DropdownMenuList
                            show={showDropdown}
                            navItems={mainMenuItems}
                            textItems={textItems}
                            align={dropdownAlign}
                            dropUp={dropUp}
                            defaultFocusPosition={focusPosition}
                            onHide={this._hideDropdown.bind(this)}
                            onTextItemSelect={this._handleTextItemSelect.bind(
                                this,
                            )}
                        />
                    )}
                    <DropdownMenuList
                        show={!!subMenuItems.length && showDropdown}
                        navItems={subMenuItemsWithHandleClicks}
                        textItems={textItems}
                        align={dropdownAlign}
                        dropUp={false}
                        defaultFocusPosition={focusPosition}
                        onHide={this._hideDropdown.bind(this)}
                        onTextItemSelect={this._handleTextItemSelect.bind(this)}
                    />
                </div>
            </HotKeys>
        );
    }
}

export default NavigationDropdownMenu;
