import React from 'react';
import { NavigationItemPropsInterface } from '../../constants/interfaces';
import NavigationItem from '../NavigationItem';
import { useNavState } from './useNavState';

const NavigationDesktop = ({
    navigationItems,
}: {
    navigationItems: NavigationItemPropsInterface[];
}) => {
    const getNavStateProps = useNavState(navigationItems);

    return (
        <>
            {navigationItems.map((navigationProps, index) => (
                <NavigationItem
                    key={`${navigationProps.content}-${index}`}
                    {...navigationProps}
                    {...getNavStateProps(index)}
                />
            ))}
        </>
    );
};

export default NavigationDesktop;
