import React from 'react';

interface RawHtmlProps {
    rawHtmlBlock: string;
}
const RawHTMLModule = ({ rawHtmlBlock }: RawHtmlProps) => (
    <div
        data-testid="raw-html-module"
        className="html_content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: rawHtmlBlock.toString() }}
    />
);
export default RawHTMLModule;
