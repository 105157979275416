import * as React from 'react';

const PencilSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="pencil_svg__eds-icon--pencil_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="pencil_svg__eds-icon--pencil_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 17.7L6.3 20l-2.9.6.6-2.9zm.3-1L15.4 5.6l3 3L7.3 19.7l-3-3zM16.1 4.9l1.4-1.4 3 3-1.4 1.4-3-3zM17.5 2L3.2 16.4 2 22l5.6-1.2L22 6.5 17.5 2z"
        />
        <g
            id="pencil_svg__eds-icon--pencil_dashes"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            <path d="M12 21h2v1h-2zM16 21h2v1h-2zM20 21h2v1h-2z" />
        </g>
    </svg>
);

PencilSvg.displayName = 'PencilSvg';
export default PencilSvg;
