import React from 'react';
import Logo from '../Logo';
import Navigation, { NavigationPropsInterface } from '../Navigation';
import './MlpHeader.scss';

const MlpHeader = ({ user, env }: NavigationPropsInterface) => {
    return (
        <section className="mlp-header mlp-header-v2">
            <div className="mlp-header__main">
                <Logo url="/" />
            </div>

            <div className="mlp-header__navigation">
                <Navigation user={user} env={env} />
            </div>
        </section>
    );
};

export default MlpHeader;
