import React from 'react';
import {
    Actions,
    Components,
    Features,
    getHeapDataAttribute,
    Teams,
} from '../../../utils/heap-data';
import { LogoInterface } from '../utils/interfaces';
import './Logo.scss';

const Logo = ({ content, url }: LogoInterface) => {
    return (
        <a
            data-heap-id={getHeapDataAttribute({
                team: Teams.SEO,
                feature: Features.OrganizerSubNav,
                component: Components.Logo,
                name: 'logo',
                action: Actions.Click,
            })}
            href={url}
            className="organizer-logo"
        >
            <span className="organizer-logo__logo">{content}</span>
        </a>
    );
};

export default Logo;
