// Vendor
import classNames from 'classnames';
import React from 'react';
// Internal
import { NavigationItemPropsInterface } from '../../constants/interfaces';
import NavigationDropdown from '../NavigationDropdown';
import NavigationLink from '../NavigationLink';
import './NavigationItem.scss';

const NavigationItem = ({
    collapseOnNarrow,
    dataAnalyticsId,
    ...navigationProps
}: NavigationItemPropsInterface) => {
    const classNameDesktop = classNames({
        'site-header__menus_desktop': collapseOnNarrow,
    });
    let component = (
        <NavigationLink
            {...navigationProps}
            collapseOnNarrow={collapseOnNarrow}
        />
    );

    if (navigationProps.navItems) {
        component = <NavigationDropdown {...navigationProps} />;
    }

    return (
        <div
            className={classNameDesktop}
            data-testid="nav-item"
            data-analytics-id={dataAnalyticsId}
        >
            {component}
        </div>
    );
};

export default NavigationItem;
