// Vendor
import classNames from 'classnames';
import React from 'react';
// Internal
import MenuIcon from '../MenuIcon';
import './DropdownMenuIcon.scss';

export interface IDropdownMenuIconProps {
    iconType: JSX.Element;
    iconColor?: string;
    narrowIconType?: JSX.Element;
    narrowIconColor?: string;
}

const DropdownMenuIcon = ({
    iconType,
    iconColor,
    narrowIconType,
    narrowIconColor,
}: IDropdownMenuIconProps) => {
    const className = classNames('eds-global-header__menu-icon', {
        'eds-show-up-md': narrowIconType,
    });
    let icon = null;

    if (narrowIconType) {
        icon = (
            <span
                className="eds-global-header__menu-icon eds-show-down-mn"
                data-testid="menu-icon-narrow-container"
            >
                <MenuIcon
                    iconType={narrowIconType}
                    iconColor={narrowIconColor}
                />
            </span>
        );
    }

    return (
        <span>
            <span className={className} data-testid="menu-icon-container">
                <MenuIcon iconType={iconType} iconColor={iconColor} />
            </span>
            {icon}
        </span>
    );
};

export default DropdownMenuIcon;
