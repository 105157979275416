import { gettext } from '@eventbrite/i18n';
import { Button as MarmaladeButton } from '@eventbrite/marmalade';
import React from 'react';
import './contactSales.scss';

const ContactSales = ({
    renderFlexiblePricing,
    supportSalesContact,
    comparePricingCTALink = '/organizer/compare-packages/',
}: {
    renderFlexiblePricing: boolean;
    supportSalesContact: boolean;
    comparePricingCTALink?: string;
}) => {
    return (
        <section className="pricing-contact-sales">
            <div className="pricing-contact-sales__content">
                <h2 className="pricing-contact-sales__content__text__header">
                    {gettext('Hosting a large or complex event?')}
                </h2>
                <p className="pricing-contact-sales__content__text__main eds-text-bm">
                    {renderFlexiblePricing
                        ? gettext(
                              'We offer tailored plans for event pros with unique needs. Get personalized support, customized pricing, and more by partnering with our sales team.',
                          )
                        : gettext(
                              'We offer tailored packages for event pros with unique needs. Get customized fee schedule, personalized support, and more individualized features by partnering with our sales team.',
                          )}
                </p>
                <div className="pricing-contact-sales__content__ctas">
                    <a
                        href={
                            supportSalesContact
                                ? '/l/contact-eventbrite-sales/'
                                : '/contact-sales/'
                        }
                    >
                        <MarmaladeButton>
                            <span className="pricing-contact-sales__content__ctas__text">
                                {gettext('Contact sales')}
                            </span>
                        </MarmaladeButton>
                    </a>
                    <a href={comparePricingCTALink}>
                        <MarmaladeButton variant="ghost">
                            <span className="pricing-contact-sales__content__ctas__text">
                                {gettext('Compare pricing')}
                            </span>
                        </MarmaladeButton>
                    </a>
                </div>
            </div>
            <div className="pricing-contact-sales__image-container">
                <img
                    className="pricing-contact-sales__image"
                    src="https://eventbrite-s3.s3.amazonaws.com/marketing/landingpages/assets/Contact+Sales_Pricing.svg"
                    alt=""
                    width={400}
                    height={300}
                />
            </div>
        </section>
    );
};

export default ContactSales;
