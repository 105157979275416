// Vendor
import { CoreEnv } from '@eventbrite/context-gen';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
// Internal
import NavigationDesktop from '../NavigationDesktop';
import NavigationMobile from '../NavigationMobile';
import { useNavigation } from './hook';
import './Navigation.scss';

export interface NavigationPropsInterface {
    collapseOnNarrow?: boolean;
    user: any;
    env: CoreEnv;
}

const Navigation = ({ user, env }: NavigationPropsInterface) => {
    const currentNavigationItems = useNavigation({
        user,
        env,
    });

    let navigationDesktop: JSX.Element | null = null;
    let navigationMobile: JSX.Element | null = null;

    const className = classNames('mlp-header__menus');
    const classNameMobile = classNames('mlp-header__menus_mobile');

    /**
     * using the index to generate a unique key in this case as no other id
     * or unique value is available; current state some labels/urls are duplicates
     * and many are static — see https://reactjs.org/docs/lists-and-keys.html
     */
    if (!isEmpty(currentNavigationItems)) {
        navigationDesktop = (
            <NavigationDesktop navigationItems={currentNavigationItems} />
        );

        navigationMobile = (
            <NavigationMobile navigationItems={currentNavigationItems} />
        );
    }

    return (
        <div className={className} data-testid="navigation-container">
            <div className={classNameMobile}>{navigationMobile}</div>
            {navigationDesktop}
        </div>
    );
};

export default Navigation;
