import logger from '@eventbrite/client-logger';
import loadable from '@loadable/component';
import RawHTMLModule from './RawHtml';

const EmptyModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'EmptyModule' */ '../sharedComponents/EmptyModule'
        ),
);
const CallToActionModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'CallToActionModule' */ './call-to-action/CallToAction'
        ),
);
const CallToActionHeroModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'CallToActionHeroModule' */ './call-to-action-hero/CallToActionHero'
        ),
);
const CreatorProductTour = loadable(
    () =>
        import(
            /* webpackChunkName: 'CreatorProductTourModule' */ './creator-product-tour/CreatorProductTour'
        ),
);
const DividerModule = loadable(
    () => import(/* webpackChunkName: 'DividerModule' */ './divider/Divider'),
);
const FAQModule = loadable(
    () => import(/* webpackChunkName: 'FAQModule' */ './faq/Faq'),
);
const FeatureModule = loadable(
    () => import(/* webpackChunkName: 'FeatureModule' */ './feature/Feature'),
);
const HeroCallToActionModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'HeroCallToActionModule' */ './hero-call-to-action/HeroCallToAction'
        ),
);
const ImageResourceCardsModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'ImageResourceCardsModule' */ './image-resource-card/ImageResourceCards'
        ),
);
const MultipleRatingsModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'MultipleRatingsModule' */ './multiple-ratings/MultipleRatings'
        ),
);
const NewPricingContentModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'NewPricingContentModule' */ './new-pricing-content/NewPricingContent'
        ),
);
const PayoutModule = loadable(
    () => import(/* webpackChunkName: 'PayoutModule' */ './payout/Payout'),
);
const PricingModule = loadable(
    () => import(/* webpackChunkName: 'PricingModule' */ './pricing/Pricing'),
);
const RightSizePricingModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'RightSizePricingModule' */ './right-size-pricing/RightSizePricing'
        ),
);
const PricingCalculatorModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'PricingCalculatorModule' */ './pricing-calculator/PricingCalculator'
        ),
);
const LegacyProductHighlightModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'ProductHighlightModule' */ './legacy-product-highlight/ProductHighlight'
        ),
);
const ProductHighlightModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'ProductHighlightModule' */ './product-highlight/ProductHighlight'
        ),
);
const LegacyResourceCardsModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'ResourceCardsModule' */ './legacy-resource-cards/ResourceCards'
        ),
);
const ResourceCardsModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'ResourceCardsModule' */ './resource-cards/ResourceCards'
        ),
);
const StandardCallToActionModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'StandardCallToActionModule' */ './standard-call-to-action/StandardCallToAction'
        ),
);
const RichTextModule = loadable(
    () =>
        import(/* webpackChunkName: 'RichTextModule' */ './rich-text/RichText'),
);
const LegacyTestimonialsModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'TestimonialsModule' */ './legacy-testimonial/Testimonial'
        ),
);
const TestimonialsModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'TestimonialsModule' */ './testimonial/Testimonial'
        ),
);
const TrustedByTestimonialModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'TrustedByTestimonialModule' */ './trusted-by-testimonial/TrustedByTestimonial'
        ),
);
const VideoModule = loadable(
    () => import(/* webpackChunkName: 'VideoModule' */ './video/Video'),
);
const NewFeatureBlockModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'FeatureBlockModule' */ './feature-block/FeatureBlock'
        ),
);
const ResourceHubModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'ResourceHubModule' */ './resource-hub/ResourceHubModule'
        ),
);
const InterlinkingCardsModule = loadable(
    () =>
        import(
            /* webpackChunkName: 'InterlinkingCardsModule' */ './interlinking-cards/InterlinkingCardsModule'
        ),
);

interface ModuleDictionary {
    faq_content: any;
    image_resource_cards_content: any;
    raw_html_content: any;
    payout_content: any;
    product_highlight_content: any;
    testimonials_content: any;
    resource_cards_content: any;
    new_resource_cards_content: any;
    feature_content: any;
    divider_content: any;
    call_to_action_content: any;
    video_content: any;
    call_to_action_hero_content: any;
    rich_text_content: any;
    pricing_content: any;
    right_size_pricing_content: any;
    pricing_calculator_content: any;
    standard_call_to_action_content: any;
    hero_call_to_action_content: any;
    new_testimonials_content: any;
    multiple_ratings_content: any;
    new_product_highlight_content: any;
    trusted_by_testimonial_content: any;
    new_pricing_content: any;
    new_feature_content: any;
    resource_hub_content: any;
    interlinking_card_content: any;
    creator_product_tour_content: any;
}

const MODULE_TYPE_TO_COMPONENT: ModuleDictionary = {
    call_to_action_content: CallToActionModule,
    divider_content: DividerModule,
    faq_content: FAQModule,
    feature_content: FeatureModule,
    image_resource_cards_content: ImageResourceCardsModule,
    pricing_content: PricingModule,
    raw_html_content: RawHTMLModule,
    payout_content: PayoutModule,
    right_size_pricing_content: RightSizePricingModule,
    product_highlight_content: LegacyProductHighlightModule,
    new_product_highlight_content: ProductHighlightModule,
    resource_cards_content: LegacyResourceCardsModule,
    new_resource_cards_content: ResourceCardsModule,
    testimonials_content: LegacyTestimonialsModule,
    new_testimonials_content: TestimonialsModule,
    video_content: VideoModule,
    call_to_action_hero_content: CallToActionHeroModule,
    rich_text_content: RichTextModule,
    pricing_calculator_content: PricingCalculatorModule,
    standard_call_to_action_content: StandardCallToActionModule,
    hero_call_to_action_content: HeroCallToActionModule,
    multiple_ratings_content: MultipleRatingsModule,
    trusted_by_testimonial_content: TrustedByTestimonialModule,
    new_pricing_content: NewPricingContentModule,
    new_feature_content: NewFeatureBlockModule,
    resource_hub_content: ResourceHubModule,
    interlinking_card_content: InterlinkingCardsModule,
    creator_product_tour_content: CreatorProductTour,
};

export const getModuleComponent = (type: string) => {
    if (!Object.prototype.hasOwnProperty.call(MODULE_TYPE_TO_COMPONENT, type)) {
        logger.warn('Unknown Wagtail module type.', { type });
        return EmptyModule;
    }

    return MODULE_TYPE_TO_COMPONENT[type as keyof ModuleDictionary];
};
