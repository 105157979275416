import { CoreApplicationContext } from '@eventbrite/context-gen';
import { Layout } from '@eventbrite/eds-layout';
import { GlobalNav } from '@eventbrite/global-nav';
import { gettext, setLanguage } from '@eventbrite/i18n';
import { MlpHeader } from '@eventbrite/mlp-header';
import { useRealUserMonitoring } from '@eventbrite/real-user-monitoring';
import { SiteStructure } from '@eventbrite/site-structure';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import { useConstructor } from '@eventbrite/use-constructor';
import { OrganizerSubNav, StickyBar } from '@eventbrite/wagtail-components';
import { WagtailToolbar } from '@eventbrite/wagtail-toolbar';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import './app.scss';
import ModuleList from './components/ModuleList';
import { logMlpPageView } from './utils/statsig/statsig';
import { AppProps } from './utils/types';
import { WagtailModulePagesBreadCrumb } from './utils/WagtailModulePagesBreadCrumb';

export const App: React.FunctionComponent<AppProps & CoreApplicationContext> = (
    props,
) => {
    const {
        modules,
        env,
        user,
        request,
        wagtail_toolbar,
        include_footer,
        footerLinks,
        should_use_webpack_template,
        app_version,
        app_name,
        breadcrumbs,
        should_display_breadcrumbs,
        theme,
        featureFlags,
        feature_flags,
    } = props;

    useConstructor(() => {
        setLanguage(env.localeInfo.locale);
    });

    const isFirstModuleStandardCtaOrHeroCta = [
        'hero_call_to_action_content',
        'standard_call_to_action_content',
    ].includes(modules['body_content'][0].type);

    const renderBreadcrumbsInSiteStructure =
        should_display_breadcrumbs && !isFirstModuleStandardCtaOrHeroCta;

    const stickyCtaFlagContent = modules['body_content'].find((moduleData) => {
        return moduleData.type == 'sticky_cta_content';
    });
    const renderStickyMobileCtaBanner =
        isFirstModuleStandardCtaOrHeroCta &&
        (!stickyCtaFlagContent || stickyCtaFlagContent.value.enable_sticky_cta);

    // Only send samples to datadog RUM if it's production
    const sessionSampleRate = env.ebDomain.indexOf('eventbrite') > -1 ? 5 : 0;
    useRealUserMonitoring({
        sessionSampleRate,
        version: app_version,
        service: app_name,
    });

    const moduleData = (
        <section className="content-container">
            <ModuleList
                env={env}
                user={user}
                theme={theme}
                modules={deepKeysToCamel(modules['body_content'])}
                breadcrumbs={
                    renderBreadcrumbsInSiteStructure ? undefined : breadcrumbs
                }
                isContentFromContentful={
                    feature_flags?.is_cms_response_from_contentful
                }
            />
        </section>
    );

    useEffect(() => {
        logMlpPageView(window?.location?.pathname ?? '');
    }, []);

    if (!should_use_webpack_template) {
        return moduleData;
    }

    const linksForFooter = include_footer ? footerLinks : null;
    let wagtailToolbar = null;

    if (wagtail_toolbar) {
        const toolbar = deepKeysToCamel(wagtail_toolbar);
        wagtailToolbar = toolbar && (
            <WagtailToolbar
                editPageLink={toolbar.editPageLink}
                loginLink={toolbar.loginLink}
            />
        );
    }

    return (
        /* Sitestructure has an error boundary built in around its children, so errors that sentry
        should be grabbing are potentially being swallowed. This ensures that the error is caught
        and not piped to the default error system. */
        <Sentry.ErrorBoundary
            fallback={<>{gettext('An error has occurred')}</>}
        >
            {wagtailToolbar}
            <SiteStructure
                env={env}
                user={user}
                request={request}
                fullScreenMain={true}
                footerLinks={linksForFooter}
                globalHeader={
                    featureFlags.enableIaGlobalNav && !user.isAuthenticated ? (
                        <GlobalNav env={env} user={user} />
                    ) : (
                        <MlpHeader env={env} user={user} />
                    )
                }
                showMarketingLinks={!user.isAuthenticated}
                additionalHeaderContent={
                    !user.isAuthenticated ? (
                        <>
                            {featureFlags.enableIaGlobalNav && (
                                <OrganizerSubNav env={env} sticky={true} />
                            )}
                            {renderStickyMobileCtaBanner && (
                                <StickyBar env={env} />
                            )}
                        </>
                    ) : undefined
                }
                breadcrumbs={
                    renderBreadcrumbsInSiteStructure ? (
                        <WagtailModulePagesBreadCrumb
                            breadcrumbs={breadcrumbs}
                            navClasses={'wagtail-breadcrumbs__white-background'}
                        />
                    ) : undefined
                }
            >
                <Layout>{moduleData}</Layout>
            </SiteStructure>
        </Sentry.ErrorBoundary>
    );
};
