// Vendor
import React from 'react';
// Internal
import MenuIcon from '../MenuIcon';
import './DropdownMenuNarrowOnlyIcon.scss';

export interface IDropdownMenuNarrowOnlyIconProps {
    narrowIconType: JSX.Element;
    narrowIconColor: string;
}

const DropdownMenuNarrowOnlyIcon = ({
    narrowIconType,
    narrowIconColor,
}: IDropdownMenuNarrowOnlyIconProps) => (
    <span className="eds-global-header__menu-icon eds-show-down-mn">
        <MenuIcon iconType={narrowIconType} iconColor={narrowIconColor} />
    </span>
);

export default DropdownMenuNarrowOnlyIcon;
