// Vendor
import { ROUTER_LINK } from '@eventbrite/eds-nav-list-item';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router';
// Internal
import { NavigationItemPropsInterface } from '../../constants/interfaces';
import { LINK_TYPE } from '../../constants/linkItem';
import { useIsMobile } from '../Navigation/hook';
import './NavigationLink.scss';

const NavigationLink = ({
    collapseOnNarrow,
    ...navigationProps
}: NavigationItemPropsInterface) => {
    const isMobile = useIsMobile();

    const { path, content, type, onClick, onMouseEnter, linkType } =
        navigationProps;
    let Component: string | Link = 'a';
    const linkProps = { to: '', href: '' };

    const quickLinkWrapperClassName = classNames('site-header__link-menu', {
        // If is pill
        'site-header__type-pill': linkType === LINK_TYPE.PILL,
        // If is blue link
        'site-header__type-link-blue': linkType === LINK_TYPE.LINK_BLUE,
        // If is link
        'site-header__type-link': linkType === LINK_TYPE.LINK,
    });

    // we only show the content when the screen is *not* "narrow". When `collapseOnNarrow`
    // is `true` we want it to persist to a "narrower" point.
    const labelClassName = collapseOnNarrow
        ? 'eds-show-up-mn'
        : 'eds-show-up-md';

    if (type === ROUTER_LINK) {
        Component = Link;
        linkProps.to = path ?? '';
    } else if ('path' in navigationProps) {
        linkProps.href = path ?? '';
    } else {
        linkProps.to = '#';
        linkProps.href = '#';
    }

    return (
        <Component
            {...linkProps}
            key={path}
            className={quickLinkWrapperClassName}
            data-testid="quick-link"
            onClick={onClick}
            onMouseEnter={onMouseEnter}
        >
            <span
                className={
                    isMobile && collapseOnNarrow
                        ? 'mlp-dropdown--link'
                        : labelClassName
                }
            >
                {content}
            </span>
        </Component>
    );
};

export default NavigationLink;
