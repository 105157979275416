// Vendor
import { ALIGN_RIGHT } from '@eventbrite/eds-containers';
import { DropdownMenu } from '@eventbrite/eds-dropdown-menu';
import classNames from 'classnames';
import { omit } from 'lodash';
import React, { MouseEvent, useEffect } from 'react';
// Internal
import { NavigationItemPropsInterface } from '../../constants/interfaces';
import { useIsMobile } from '../Navigation/hook';
import NavigationDropdownMenu from '../NavigationDropdownMenu';
import UserAvatar from '../UserAvatar';
import './NavigationDropdown.scss';

const NavigationDropdown = (navigationItem: NavigationItemPropsInterface) => {
    const {
        content,
        fallbackUrl,
        navItems,
        className,
        imageUrl,
        iconType,
        narrowIconType,
        noLabel,
        navState,
        updateNavState = () => ({}),
        removeOnClickOutsideClass = () => ({}),
        mergeOnMobile,
        onClick,
        onMouseEnter,
    } = navigationItem;

    const isMobile = useIsMobile();

    const hasImageOrIcon = imageUrl || iconType || narrowIconType;
    const isTouchableDevice = globalThis.document
        ? document.documentElement?.ontouchstart !== undefined
        : false;

    useEffect(() => {
        removeOnClickOutsideClass();
    }, [removeOnClickOutsideClass]);

    const navigationDropdownClassName = classNames(
        className,
        'site-header__navigation-dropdown',
        {
            'site-header__navigation-dropdown--avatar':
                navigationItem.avatarProps,

            'site-header__navigation-dropdown--with-icon': hasImageOrIcon,
        },
    );

    const contentSpanClassName = classNames('eds-global-header__menu-label', {
        'eds-global-header__menu-label-hide': hasImageOrIcon,
    });

    const dropdownMenuProps = omit(navigationItem, [
        'avatarProps',
        'mergeOnMobile',
        'shouldShow',
        'linkType',
        'collapseOnNarrow',
        'noLabel',
        'iconType',
        'iconColor',
        'secondaryContent',
        'imageUrl',
        'narrowIconType',
        'narrowIconColor',
        'isActive',
        'showOnlyOnMobile',
        'fallbackUrl',
        'navState',
        'updateNavState',
        'removeOnClickOutsideClass',
    ]);

    const navItemProps = navItems?.map((item) =>
        omit(item, [
            'avatarProps',
            'mergeOnMobile',
            'shouldShow',
            'linkType',
            'collapseOnNarrow',
            'noLabel',
            'isActive',
            'showOnlyOnMobile',
            'fallbackUrl',
        ]),
    );

    const navItemPropsMobile = navItems?.map((item) =>
        omit(item, [
            'shouldShow',
            'mergeOnMobile',
            'collapseOnNarrow',
            'fallbackUrl',
        ]),
    );

    if (isMobile && !mergeOnMobile) {
        return (
            <div className="eds-dropdown-menu">
                <NavigationDropdownMenu
                    {...dropdownMenuProps}
                    hideDropIconWhenNarrow={true}
                    fallbackUrl={fallbackUrl}
                    navItems={navItemPropsMobile}
                    preventMouseOver
                    dropdownAlign={ALIGN_RIGHT}
                    className="ignore-react-onclickoutside"
                >
                    <UserAvatar {...navigationItem} />
                </NavigationDropdownMenu>
            </div>
        );
    } else {
        return (
            <div className={navigationDropdownClassName}>
                <DropdownMenu
                    {...dropdownMenuProps}
                    fallbackUrl={fallbackUrl}
                    navItems={navItemProps}
                    dropdownAlign={ALIGN_RIGHT}
                    preventMouseOver={isTouchableDevice}
                    hideDropIconWhenNarrow={!!hasImageOrIcon}
                    onClickDropdown={(e: MouseEvent) => {
                        updateNavState();
                        onClick?.(e);
                    }}
                    onMouseEnterDropdownMenu={onMouseEnter}
                    className={
                        navState?.shouldIgnoreOnClickOutside
                            ? 'ignore-react-onclickoutside'
                            : ''
                    }
                >
                    <UserAvatar {...navigationItem} />
                    {!noLabel && (
                        <span
                            className={contentSpanClassName}
                            data-testid="menu-label"
                        >
                            {content}
                        </span>
                    )}
                </DropdownMenu>
            </div>
        );
    }
};

export default NavigationDropdown;
