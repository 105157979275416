export const STARTING_PRICES: { [key: string]: string } = {
    '.com': '$9 USD',
    '.ca': '$12 CAD',
    '.co.uk': '£7',
    '.com.au': '$9 AUD',
    '.ie': '€9 ',
    '.co.nz': '$9 NZD',
};

export const HELP_ARTICLES: { [key: string]: string } = {
    '.com': '/help/en-us/articles/193833/',
    '.ca': '/help/en-ca/articles/193833/',
    '.co.uk': 'help/en-gb/articles/193833/',
    '.com.au': '/help/en-au/articles/193833/',
    '.ie': '/help/en-ie/articles/193833/',
    '.co.nz': '/help/en-nz/articles/313813/9',
};
