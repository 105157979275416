import {
    AlertChunky as AlertChunkySvg,
    FlagChunky as FlagChunkySvg,
    GlobeChunky as GlobeChunkySvg,
    MlpCharity,
    MlpFood,
    MlpMusic,
    MlpPerforming,
    MlpRetail,
} from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { DropdownItem } from '../../components/CategoryDropdown/CategoryDropdown';
import DropdownTicketContent from '../../components/DropdownTicketContent';
import { BoxColor } from '../../components/types';
import { linkCreator } from './linkCreator';

export const eventTicketing = (tld: string, serverUrl: string) => {
    const linkPath: Record<string, string> = {
        // any tld not added to this dictionary will default to /l/sell-tickets/
        default: '/l/sell-tickets/',
        '.ca': '/l/sell-tickets-online/',
    };
    return linkCreator({
        content: <DropdownItem title={gettext('Event Ticketing')} />,
        path: `${serverUrl}${linkPath[tld] || linkPath['default']}`,
        onMouseEnter: {
            label: 'GA_LABEL_EVENT_TICKETING',
            action: 'GA_ACTION_EVENT_TICKETING_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_SOLUTIONS',
            action: 'GA_ACTION_EVENT_TICKETING_CLICK',
        },
    });
};

export const eventMarketingPlatform = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Event Marketing Platform')} />,
        path: `${serverUrl}/l/event-marketing-platform/`,
        onMouseEnter: {
            label: 'GA_LABEL_EVENT_MARKETING_SUITE',
            action: 'GA_ACTION_EVENT_MARKETING_SUITE_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_EVENT_MARKETING_SUITE',
            action: 'GA_ACTION_EVENT_MARKETING_SUITE_CLICK',
        },
    });

export const paymentsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Payments')} />,
        path: `${serverUrl}/l/event-payment/`,
        onMouseEnter: {
            label: 'GA_LABEL_PAYMENTS_SUITE',
            action: 'GA_ACTION_PAYMENTS_SUITE_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_PAYMENTS_SUITE',
            action: 'GA_ACTION_PAYMENTS_SUITE_CLICK',
        },
    });

export const adsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Eventbrite Ads')} />,
        path: `${serverUrl}/l/eventbrite-ads/`,
        onMouseEnter: {
            label: 'GA_LABEL_ADS',
            action: 'GA_ACTION_ADS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_ADS',
            action: 'GA_ACTION_ADS_CLICK',
        },
    });

export const organizerResourceHubLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Organizer Resource Hub')} />,
        path: `${serverUrl}/resources/`,
    });

export const createEventsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Create Events')} />,
        path: `${serverUrl}/organizer/overview/`,
        onMouseEnter: {
            label: 'GA_LABEL_WHY_EVENTBRITE',
            action: 'GA_ACTION_WHY_EVENTBRITE_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_WHY_EVENTBRITE',
            action: 'GA_ACTION_WHY_EVENTBRITE_CLICK',
        },
    });

export const organizerLink = (serverUrl: string) =>
    linkCreator({
        content: gettext('Organize'),
        path: `${serverUrl}/organizer/overview/`,
        collapseOnNarrow: true,
    });

export const helpLink = (serverUrl: string) =>
    linkCreator({
        content: gettext('Help'),
        path: `${serverUrl}/support`,
        collapseOnNarrow: true,
    });

export const successStoriesLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Success Stories')} />,
        path: `${serverUrl}/blog/case-studies/`,
        onMouseEnter: {
            label: 'GA_LABEL_SUCCESS_STORIES',
            action: 'GA_ACTION_SUCCESS_STORIES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_SUCCESS_STORIES',
            action: 'GA_ACTION_SUCCESS_STORIES_CLICK',
        },
    });

export const communityLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Community')} />,
        path: `${serverUrl}/reconvene/`,
        onMouseEnter: {
            label: 'GA_LABEL_COMMUNITY',
            action: 'GA_ACTION_COMMUNITY_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_COMMUNITY',
            action: 'GA_ACTION_COMMUNITY_CLICK',
        },
    });

export const blogLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Blog')} />,
        path: `${serverUrl}/blog/`,
        onMouseEnter: {
            label: 'GA_LABEL_BLOG',
            action: 'GA_ACTION_BLOG_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_BLOG',
            action: 'GA_ACTION_BLOG_CLICK',
        },
    });

export const contactSalesLink = (tld: string, serverUrl: string) => {
    const linkPath: Record<string, string> = {
        // any tld not added to this dictionary will default to/l/contact-eventbrite-sales/
        default: '/l/contact-eventbrite-sales/',
    };
    return linkCreator({
        content: <DropdownItem title={gettext('Contact Sales')} />,
        path: `${serverUrl}${linkPath[tld] || linkPath['default']}`,
        onMouseEnter: {
            label: 'GA_LABEL_CONTACT_SALES',
            action: 'GA_ACTION_CONTACT_SALES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_CONTACT_SALES',
            action: 'GA_ACTION_CONTACT_SALES_CLICK',
        },
    });
};

export const supportLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Support')} />,
        path: `${serverUrl}/support`,
        onMouseEnter: {
            label: 'GA_LABEL_SUPPORT',
            action: 'GA_ACTION_SUPPORT_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_SUPPORT',
            action: 'GA_ACTION_SUPPORT_CLICK',
        },
    });

export const concertsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Concerts')} />,
        path: `${serverUrl}/l/music-venues/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_CONCERTS',
            action: 'GA_ACTION_CONCERTS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_CONCERTS',
            action: 'GA_ACTION_CONCERTS_CLICK',
        },
    });

export const classesAndWorkshopsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Classes & Workshops')} />,
        path: `${serverUrl}/l/create-a-workshop/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_CLASSES_AND_WORKSHOPS',
            action: 'GA_ACTION_CLASSES_AND_WORKSHOPS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_CLASSES_AND_WORKSHOPS',
            action: 'GA_ACTION_CLASSES_AND_WORKSHOPS_CLICK',
        },
    });

export const festivalAndFairsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Festivals & Fairs')} />,
        path: `${serverUrl}/l/festival-solutions/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_FESTIVAL_AND_FAIRS',
            action: 'GA_ACTION_FESTIVAL_AND_FAIRS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_FESTIVAL_AND_FAIRS',
            action: 'GA_ACTION_FESTIVAL_AND_FAIRS_CLICK',
        },
    });

export const nightLifeLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Nightlife')} />,
        // TODO: new-mlp
        path: `${serverUrl}`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_NIGHT_LIFE',
            action: 'GA_ACTION_NIGHT_LIFE_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_NIGHT_LIFE',
            action: 'GA_ACTION_NIGHT_LIFE_CLICK',
        },
    });

export const conferencesLink = (tld: string, serverUrl: string) => {
    const linkPath: Record<string, string> = {
        // any tld not added to this dictionary will default to conferences /l/conferences/
        default: '/l/conferences/',
        '.co.uk': '/l/conference-planning/',
    };
    return linkCreator({
        content: <DropdownItem title={gettext('Conferences')} />,
        path: `${serverUrl}${linkPath[tld] || linkPath['default']}`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_CONFERENCES',
            action: 'GA_ACTION_CONFERENCES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_CONFERENCES',
            action: 'GA_ACTION_CONFERENCES_CLICK',
        },
    });
};

export const corporateEventsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Corporate Events')} />,
        path: `${serverUrl}/l/eventbrite-for-business/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_CORPORATE_EVENTS',
            action: 'GA_ACTION_CORPORATE_EVENTS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_CORPORATE_EVENTS',
            action: 'GA_ACTION_CORPORATE_EVENTS_CLICK',
        },
    });

export const onlineEventsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Online Events')} />,
        path: `${serverUrl}/l/virtual-events-platform/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_ONLINE_EVENTS',
            action: 'GA_ACTION_ONLINE_EVENTS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_ONLINE_EVENTS',
            action: 'GA_ACTION_ONLINE_EVENTS_CLICK',
        },
    });

export const musicLink = (serverUrl: string) =>
    linkCreator({
        content: (
            <DropdownItem
                title={gettext('Music')}
                boxColor={BoxColor.slimeGreen}
                iconType={<MlpMusic />}
            />
        ),
        path: `${serverUrl}/l/music/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_MUSIC',
            action: 'GA_ACTION_MUSIC_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_MUSIC',
            action: 'GA_ACTION_MUSIC_CLICK',
        },
    });

export const foodAndBeverageLink = (serverUrl: string) =>
    linkCreator({
        content: (
            <DropdownItem
                title={gettext('Food & Beverage')}
                boxColor={BoxColor.uiGreen}
                iconType={<MlpFood />}
            />
        ),
        path: `${serverUrl}/l/food-drink-event-ticketing/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_FOOD_AND_BEVERAGE',
            action: 'GA_ACTION_FOOD_AND_BEVERAGE_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_FOOD_AND_BEVERAGE',
            action: 'GA_ACTION_FOOD_AND_BEVERAGE_CLICK',
        },
    });

export const performingAndVisualArtsLink = (serverUrl: string) =>
    linkCreator({
        content: (
            <DropdownItem
                title={gettext('Performing Arts')}
                boxColor={BoxColor.indigoBlue}
                iconType={<MlpPerforming />}
            />
        ),
        path: `${serverUrl}/l/performing-arts/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_PERFORMING_AND_VISUAL_ARTS',
            action: 'GA_ACTION_PERFORMING_AND_VISUAL_ARTS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_PERFORMING_AND_VISUAL_ARTS',
            action: 'GA_ACTION_PERFORMING_AND_VISUAL_ARTS_CLICK',
        },
    });

export const professionalServicesLink = (serverUrl: string) =>
    linkCreator({
        content: (
            <DropdownItem
                title={gettext('Professional Services')}
                boxColor={BoxColor.slimeGreen}
                iconType={<MlpMusic />}
            />
        ),
        // TODO: New MLP to be created
        path: `${serverUrl}/l/virtual-events-platform/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_PROFESSIONAL_SERVICES',
            action: 'GA_ACTION_PROFESSIONAL_SERVICES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_PROFESSIONAL_SERVICES',
            action: 'GA_ACTION_PROFESSIONAL_SERVICES_CLICK',
        },
    });

export const charityAndCausesLink = (serverUrl: string) =>
    linkCreator({
        content: (
            <DropdownItem
                title={gettext('Charity & Causes')}
                boxColor={BoxColor.navyBlue}
                iconType={<MlpCharity />}
            />
        ),
        path: `${serverUrl}/l/npo/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_CHARITY_AND_CAUSES',
            action: 'GA_ACTION_CHARITY_AND_CAUSES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_CHARITY_AND_CAUSES',
            action: 'GA_ACTION_CHARITY_AND_CAUSES_CLICK',
        },
    });

export const healthAndFitnessLink = (serverUrl: string) =>
    linkCreator({
        content: (
            <DropdownItem
                title={gettext('Health & Fitness')}
                boxColor={BoxColor.navyBlue}
                iconType={<MlpMusic />}
            />
        ),
        // TODO: New MLP to be created
        path: `${serverUrl}/l/virtual-events-platform/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_HEALTH_AND_FITNESS',
            action: 'GA_ACTION_HEALTH_AND_FITNESS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_HEALTH_AND_FITNESS',
            action: 'GA_ACTION_HEALTH_AND_FITNESS_CLICK',
        },
    });

export const retailLink = (serverUrl: string) =>
    linkCreator({
        content: (
            <DropdownItem
                title={gettext('Retail')}
                boxColor={BoxColor.navyBlue}
                iconType={<MlpRetail />}
            />
        ),
        path: `${serverUrl}/l/host-retail-events/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_RETAIL',
            action: 'GA_ACTION_RETAIL_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_RETAIL',
            action: 'GA_ACTION_RETAIL_CLICK',
        },
    });

export const manageEventsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Manage my events')} />,
        path: `${serverUrl}/myevents/`,
        mergeOnMobile: true,
        collapseOnNarrow: true,
        onMouseEnter: {
            label: 'GA_LABEL_MANAGE_EVENTS',
            action: 'GA_ACTION_MANAGE_EVENTS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_MANAGE_EVENTS',
            action: 'GA_ACTION_MANAGE_EVENTS_CLICK',
        },
    });

export const adminLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Admin')} />,
        path: `${serverUrl}/admin/search/`,
        mergeOnMobile: true,
        iconType: <GlobeChunkySvg />,
        iconColor: 'grey-600',
        onMouseEnter: {
            label: 'GA_LABEL_ADMIN',
            action: 'GA_ACTION_ADMIN_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_ADMIN',
            action: 'GA_ACTION_ADMIN_CLICK',
        },
    });

export const tikibarLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Tikibar')} />,
        path: `${serverUrl}/tikibar/settings`,
        mergeOnMobile: true,
        iconType: <AlertChunkySvg />,
        iconColor: 'grey-600',
        onMouseEnter: {
            label: 'GA_LABEL_TIKIBAR',
            action: 'GA_ACTION_TIKIBAR_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_TIKIBAR',
            action: 'GA_ACTION_TIKIBAR_CLICK',
        },
    });

export const featureFlagsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Feature Flags')} />,
        path: `${serverUrl}/admin/nexus/gargoyle/`,
        iconType: <FlagChunkySvg />,
        iconColor: 'grey-600',
        onMouseEnter: {
            label: 'GA_LABEL_FEATURE_FLAGS',
            action: 'GA_ACTION_FEATURE_FLAGS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_FEATURE_FLAGS',
            action: 'GA_ACTION_FEATURE_FLAGS_CLICK',
        },
    });

export const accountSettingsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Account settings')} />,
        path: `${serverUrl}/account-settings/`,
        onMouseEnter: {
            label: 'GA_LABEL_ACCOUNT_SETTINGS',
            action: 'GA_ACTION_ACCOUNT_SETTINGS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_ACCOUNT_SETTINGS',
            action: 'GA_ACTION_ACCOUNT_SETTINGS_CLICK',
        },
    });

export const collectionsLink = (serverUrl: string, publicId: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Collections')} />,
        path: `${serverUrl}/u/${publicId}/favorites/collections`,
        onMouseEnter: {
            label: 'GA_LABEL_COLLECTIONS',
            action: 'GA_ACTION_COLLECTIONS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_COLLECTIONS',
            action: 'GA_ACTION_COLLECTIONS_CLICK',
        },
    });

export const creditsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Credits')} />,
        path: `${serverUrl}/u/credits/`,
        onMouseEnter: {
            label: 'GA_LABEL_CREDITS',
            action: 'GA_ACTION_CREDITS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_CREDITS',
            action: 'GA_ACTION_CREDITS_CLICK',
        },
    });

export const followingLink = (serverUrl: string, publicId: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Following')} />,
        path: `${serverUrl}/u/${publicId}/followed`,
        onMouseEnter: {
            label: 'GA_LABEL_FOLLOWING',
            action: 'GA_ACTION_FOLLOWING_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_FOLLOWING',
            action: 'GA_ACTION_FOLLOWING_CLICK',
        },
    });

export const interestsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Interests')} />,
        path: `${serverUrl}/u/interests/`,
        onMouseEnter: {
            label: 'GA_LABEL_INTERESTS',
            action: 'GA_ACTION_INTERESTS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_INTERESTS',
            action: 'GA_ACTION_INTERESTS_CLICK',
        },
    });

export const likesSubLink = (serverUrl: string, publicId: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Liked')} />,
        path: `${serverUrl}/u/${publicId}/saved`,
        onMouseEnter: {
            label: 'GA_LABEL_LIKES',
            action: 'GA_ACTION_LIKES_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_LIKES',
            action: 'GA_ACTION_LIKES_CLICK',
        },
    });

export const logOutLink = ({
    logoutPath,
}: {
    logoutPath: string;
    email?: string;
}) =>
    linkCreator({
        content: <DropdownItem title={gettext('Log out')} />,
        path: logoutPath,
        onMouseEnter: {
            label: 'GA_LABEL_LOGOUT',
            action: 'GA_ACTION_LOGOUT_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_LOGOUT',
            action: 'GA_ACTION_LOGOUT_CLICK',
        },
    });

export const ticketsSubLink = (
    serverUrl: string,
    publicId: string,
    tickets: number,
) =>
    linkCreator({
        content: <DropdownTicketContent tickets={tickets} />,
        path: `${serverUrl}/u/${publicId}/`,
        onMouseEnter: {
            label: 'GA_LABEL_TICKETS',
            action: 'GA_ACTION_TICKETS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_TICKETS',
            action: 'GA_ACTION_TICKETS_CLICK',
        },
    });

export const browseEventsLink = (serverUrl: string) =>
    linkCreator({
        content: <DropdownItem title={gettext('Browse events')} />,
        path: `${serverUrl}/d/local/all-events/`,
        onMouseEnter: {
            label: 'GA_LABEL_BROWSE_EVENTS',
            action: 'GA_ACTION_BROWSE_EVENTS_HOVER',
        },
        onClick: {
            label: 'GA_LABEL_BROWSE_EVENTS',
            action: 'GA_ACTION_BROWSE_EVENTS_CLICK',
        },
    });
