import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@eventbrite/eds-icon';
import { Pencil } from '@eventbrite/eds-iconography';
import { Exit } from '@eventbrite/eds-iconography';

import './WagtailToolbar.scss';

export class WagtailToolbar extends React.Component {
    static propTypes = {
        editPageLink: PropTypes.string,
        loginLink: PropTypes.string,
    };

    render() {
        const { editPageLink, loginLink } = this.props;

        return (
            <div className="wagtail-toolbar l-align-center">
                <ul className="inline-link-list">
                    <li>
                        <h3 className="heading-secondary-responsive">
                            Wagtail for Eventbrite
                        </h3>
                    </li>
                    <li>
                        <a
                            href={editPageLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Icon type={<Pencil />} size="xsmall" />
                            Edit this page in Wagtail
                        </a>
                    </li>
                    <li>
                        <a
                            href={loginLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Icon type={<Exit />} size="xsmall" />
                            Login to Wagtail
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default WagtailToolbar;
