export enum ESizes {
    xlarge = 'xlarge',
    large = 'large',
    medium = 'medium',
    small = 'small',
}

export enum EBreakpointValues {
    xlarge = 1150,
    large = 792,
    medium = 660,
    small = 0,
}
