import { gettext } from '@eventbrite/i18n';
import {
    blogLinkNavItems,
    eventLinkNavItems,
    industryLinkNavItems,
    solutionsLinkNavItems,
} from './dropDownLinks';
import { LinkCreatorPropsInterface, LogoInterface } from './interfaces';

const solutionsLink = (serverUrl: string, tld: string) => {
    const link: LinkCreatorPropsInterface = {
        content: gettext('Solutions'),
        navItems: solutionsLinkNavItems(serverUrl, tld),
        name: 'solutions',
    };
    return link;
};

const industryLink = (serverUrl: string) => {
    const link: LinkCreatorPropsInterface = {
        content: gettext('Industry'),
        navItems: industryLinkNavItems(serverUrl),
        name: 'industry',
    };
    return link;
};

const eventTypesLink = (serverUrl: string, tld: string) => {
    const link: LinkCreatorPropsInterface = {
        content: gettext('Event Types'),
        navItems: eventLinkNavItems(serverUrl, tld),
        name: 'event-types',
    };
    return link;
};

const blogLink = (serverUrl: string) => {
    const link: LinkCreatorPropsInterface = {
        content: gettext('Blog'),
        navItems: blogLinkNavItems(serverUrl),
        name: 'blog',
    };
    return link;
};

const pricingLink = (serverUrl: string) => {
    const link: LinkCreatorPropsInterface = {
        content: gettext('Pricing'),
        url: `${serverUrl}/organizer/pricing/`,
        name: 'pricing',
    };
    return link;
};

export const contactLink = (serverUrl: string) => {
    const link: LinkCreatorPropsInterface = {
        content: gettext('Contact Sales'),
        url: `${serverUrl}/l/contact-eventbrite-sales/`,
        name: 'contact-sales',
    };
    return [link];
};

const organizerResourceHubLink = (serverUrl: string) => {
    const link: LinkCreatorPropsInterface = {
        content: gettext('Organizer Resource Hub'),
        url: `${serverUrl}/resources/`,
        name: 'organizer-resource-hub',
    };
    return link;
};

export const headerLinks = (serverUrl: string, tld: string) => {
    return [
        solutionsLink(serverUrl, tld),
        industryLink(serverUrl),
        eventTypesLink(serverUrl, tld),
        blogLink(serverUrl),
        ...(tld == '.com' ? [organizerResourceHubLink(serverUrl)] : []),
        pricingLink(serverUrl),
    ];
};

export const organizerOverviewLink = (serverUrl: string) => {
    const link: LogoInterface = {
        content: gettext('Organizer'),
        url: `${serverUrl}/organizer/overview/`,
    };
    return link;
};
