import * as React from 'react';

const MlpFoodSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            clipRule="evenodd"
            d="M15 12l13.242 28.373c.491.51.758 1.057.758 1.627 0 2.761-6.268 5-14 5S1 44.761 1 42c0-.57.267-1.118.76-1.629L15 12z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M15 12l6.624 14.192A5.002 5.002 0 0023 36a4.977 4.977 0 002.8-.858l2.442 5.231c.491.51.758 1.057.758 1.627 0 2.761-6.268 5-14 5S1 44.761 1 42c0-.57.267-1.118.76-1.629l2.263-4.852a5 5 0 101.412-3.026l3.13-6.702a5 5 0 004.185-8.968L15 12z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19 4l16-3 4 16"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.747 34.703L24 11h23l-2.654 36H26.654l-.152-2.055M34 18h12.114M25 18h3-3z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

MlpFoodSvg.displayName = 'MlpFoodSvg';
export default MlpFoodSvg;
