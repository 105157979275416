import * as React from 'react';

const MlpPerformingSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
        <path
            d="M26.079 14.655c2.365.22 5.06.345 7.921.345 5.035 0 9.558-.386 12.67-1H47l-1.593 22C45.145 42.12 40.138 47 34 47c-6.139 0-11.145-4.88-11.406-10.999l-.385-5.243M32.044 21.737s-2.95-1.265-6.059-.26"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M45.856 22.565c-1.75-.67-3.74-1.356-5.685-1.288-2.722.095-4.58 2.517-2.434 9.3.334 1.057 1.317 2.877.247 3.743-1.252 1.016-3.47-.681-3.47-.681M29.102 38.14c2.401-.58 4.932-.405 7.075 1.612"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.45 26.595a.563.563 0 00.568.557.564.564 0 00.532-.595.564.564 0 00-.572-.557.563.563 0 00-.528.595zM41.45 26.595a.563.563 0 00.568.557.564.564 0 00.532-.595.564.564 0 00-.572-.557.563.563 0 00-.528.595z"
            fill="#1D0A32"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M27 1l-1.593 22C25.145 29.12 20.138 34 14 34S2.855 29.12 2.594 23.001L2.588 23 1 1h.33C4.441 1.614 8.964 2 14 2s9.558-.386 12.67-1H27z"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.956 8.737s3.316-.975 5.16-.286c1.346.501 2.274 1.598 2.274 1.598M2.116 9.5c1.678-.735 3.788-1.29 5.713-1.223 2.722.095 4.58 2.517 2.435 9.3-.335 1.057-1.318 2.877-.248 3.743 1.252 1.016 3.47-.681 3.47-.681M10.83 25.537c2.401.581 4.932.406 7.076-1.612"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.55 13.595a.563.563 0 01-.568.557.564.564 0 01-.532-.595.564.564 0 01.572-.557c.302.01.539.277.528.595zM6.55 13.595a.563.563 0 01-.568.557.564.564 0 01-.532-.595.564.564 0 01.572-.557c.302.01.539.277.528.595z"
            fill="#1D0A32"
            stroke="#1D0A32"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

MlpPerformingSvg.displayName = 'MlpPerformingSvg';
export default MlpPerformingSvg;
