// Vendor
import { Avatar, AvatarProps, AvatarSize } from '@eventbrite/eds-avatar';
import React from 'react';

export interface IDropdownMenuAvatarProps {
    avatarProps: AvatarProps;
}

const DropdownMenuAvatar = ({ avatarProps }: IDropdownMenuAvatarProps) => (
    <Avatar size={AvatarSize.small} {...avatarProps} />
);

export default DropdownMenuAvatar;
