export enum Teams {
    SEO = 'seo',
}

export enum Features {
    OrganizerSubNav = 'organizer-sub-nav',
}

export enum Components {
    Logo = 'logo',
    Link = 'link',
    Dropdown = 'dropdown',
    CTA = 'cta',
    DropdownItem = 'dropdown-item',
}

export enum Actions {
    Start = 'start',
    ChooseFile = 'choose_file',
    Submit = 'submit',
    Create = 'create',
    Manage = 'manage',
    Click = 'click',
    Subscribe = 'click_subscribe',
    Select = 'select',
    Deselect = 'deselect',
}
