import { initializeErrorReporting } from '@eventbrite/error-reporting';
import {
    getLocaleOverride,
    getParentLocale,
    setupAsync,
} from '@eventbrite/i18n';
import {
    initDimensionsFromState,
    trackInitialPageView,
} from '@eventbrite/site-analytics';
import { loadableReady } from '@loadable/component';
import React from 'react';
import { hydrate } from 'react-dom';
import { App } from './app';
import {
    getUserTrackingIDs,
    initializeStatsigSdk,
} from './utils/statsig/statsig';
import { StatsigInitializationOptions } from './utils/types';

// retrieve data from server to hydrate on the client
const props = window.__SERVER_DATA__ || ({} as any);
const parentLocale = getParentLocale(
    getLocaleOverride() || props.env.localeInfo.locale,
);
const getStatsigInitializationOptions = (): StatsigInitializationOptions => ({
    user: {
        customIDs: getUserTrackingIDs(props.request),
        userID: props?.user?.publicId,
    },
});

const main = async () => {
    initializeErrorReporting();
    try {
        if (parentLocale !== 'en_US') {
            await setupAsync({
                parentLocale,
                translations:
                    /* webpackChunkName: "wagtail-module-pages-i18n-translation" */ import(
                        `./i18n/translations/${parentLocale}.json`
                    ),
            });
        }

        const statsigOptions = getStatsigInitializationOptions();
        await initializeStatsigSdk(statsigOptions);
    } finally {
        loadableReady(() => {
            hydrate(<App {...props} />, document.getElementById('root'));
        });
    }
};
main();

initDimensionsFromState(props);
trackInitialPageView(props.gaSettings);
