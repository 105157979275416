import { Icon } from '@eventbrite/eds-icon';
import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
import { v4 as UUID } from 'uuid';
import { CategoryDropdownProps, DropdownItemProps } from '../types';
import './CategoryDropdown.scss';

export const DropdownItem = ({
    title = gettext('Category'),
    boxColor = 'slime-green',
    iconType,
}: DropdownItemProps) => {
    const classes = classNames('dropdown-item-icon-box', {
        [`dropdown-item-icon-box--${boxColor}`]: boxColor,
    });

    return (
        <div className="dropdown-item-container">
            {iconType && (
                <div className={classes}>
                    <Icon type={iconType} title={title} />
                </div>
            )}

            <div className="dropdown-item-title">{title}</div>
        </div>
    );
};

export const CategoryDropdown = ({ items }: CategoryDropdownProps) => {
    return (
        <div className="category-dropdown-container">
            {items.map((item) => (
                <DropdownItem
                    title={item.title}
                    boxColor={item.boxColor}
                    iconType={item.iconType}
                    key={UUID()}
                />
            ))}
        </div>
    );
};
