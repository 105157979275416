import { HAS_WINDOW } from '@eventbrite/feature-detection';
import * as React from 'react';
import { ThemeTokens, THEME_TYPES, TokenOptions, VARIANTS } from '../types';
import {
    CachedStyle,
    createThemeStylesFromCache,
    generateCacheKey,
    generateThemeStyles,
} from './utils';

interface ThemeContextInterface {
    theme: THEME_TYPES | 'Core';
    generateStyles: (
        token: ThemeTokens,
        options?: TokenOptions,
    ) => Array<string>;
}

export const ThemeContext = React.createContext<ThemeContextInterface>({
    theme: THEME_TYPES.EDGY,
    generateStyles: (token: ThemeTokens, options?: TokenOptions) => {
        return generateThemeStyles(THEME_TYPES.EDGY, token, options);
    },
});

export type ThemeContextProps = {
    theme?: THEME_TYPES | 'Core';
};

const defaultCachedStyles: Record<string, CachedStyle> = HAS_WINDOW
    ? window.__THEME_CONTEXT_CACHE__ || {}
    : {};

let serverThemeCache: Record<string, CachedStyle> = {};
const scriptHTML = {
    __html: `window.__THEME_CONTEXT_CACHE__=${JSON.stringify(
        HAS_WINDOW ? {} : serverThemeCache,
    )}`,
};

export const ThemeContextProvider = (
    props: React.PropsWithChildren<ThemeContextProps>,
) => {
    // Store the CSS style string in state so we can redraw the page after all
    // Theme styles have been requested
    const [themeStyles, setThemeStyles] = React.useState(
        createThemeStylesFromCache(defaultCachedStyles),
    );
    // We want one function per theme and then make a new one when there is a new theme
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const generateStyles = React.useCallback(
        // Using an Immediately Invoked Function Expression (IIFE) to create a scoped cache object to memoize the CSS results
        // Since useCallback with memoize the function that is returned based on the array
        // of dependencies, this ensures that a memoized function will have a single cache
        // available to it via the closure scope of the IIFE.
        (function (cachedStyles) {
            return (token: ThemeTokens, options?: TokenOptions) => {
                const optionsWithVariant = {
                    variant: VARIANTS.CORE_DARK,
                    ...options,
                };
                const cacheKey = generateCacheKey(token, optionsWithVariant);
                const cachedStyle = cachedStyles[cacheKey];
                if (cachedStyle) {
                    return [cachedStyle.className, cachedStyle.styles];
                }
                const theme = props.theme || THEME_TYPES.EDGY;
                const [className, styles] = generateThemeStyles(
                    theme,
                    token,
                    optionsWithVariant,
                );
                cachedStyles[cacheKey] = {
                    className,
                    styles,
                };
                if (!HAS_WINDOW) {
                    serverThemeCache = cachedStyles;
                    scriptHTML.__html = `window.__THEME_CONTEXT_CACHE__=${JSON.stringify(
                        HAS_WINDOW ? {} : serverThemeCache,
                    )}`;
                }
                setThemeStyles(createThemeStylesFromCache(cachedStyles));
                return [className, styles];
            };
        })({ ...defaultCachedStyles }),
        [props.theme],
    );

    return (
        <ThemeContext.Provider
            value={{
                theme: props.theme || THEME_TYPES.EDGY,
                generateStyles,
            }}
        >
            <style>{themeStyles}</style>
            {props.children}
            <script
                // set the cache for client hydration if on the server
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={scriptHTML}
            ></script>
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    return React.useContext(ThemeContext);
};
