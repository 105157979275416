import { CoreEnv } from '@eventbrite/context-gen';
import { DIVIDER } from '@eventbrite/eds-nav-list-item';
import { UserPropsInterface } from '../../constants/interfaces';
import { getAvatarProps } from '../getAvatar';
import {
    accountSettingsLink,
    adminLink,
    browseEventsLink,
    creditsLink,
    featureFlagsLink,
    followingLink,
    interestsLink,
    likesSubLink,
    logOutLink,
    manageEventsLink,
    ticketsSubLink,
    tikibarLink,
} from './dropdownLinks';
import { linkCreator } from './linkCreator';

export const cachedHeaderAvatarDropdown = ({
    env,
}: {
    env: Partial<CoreEnv>;
}) => ({
    content: 'My Account',
    shouldShow: false,
    avatarProps: getAvatarProps({
        backgroundColor: 'ui-blue--active',
        iconColor: 'grey-100',
    }),
    navItems: [
        browseEventsLink(env?.serverUrl ?? ''),
        manageEventsLink(env?.serverUrl ?? ''),
        { type: DIVIDER },
        accountSettingsLink(env?.serverUrl ?? ''),
        logOutLink({ logoutPath: env?.logoutUrl ?? '' }),
    ],
});

export const adminAvatarDropdown = ({
    env,
    user,
}: {
    env: Partial<CoreEnv>;
    user: UserPropsInterface;
}) => {
    const { fullName, thumbnailUrl, publicId, numOrders, email } = user;
    const { serverUrl, logoutUrl } = env;
    return {
        content: fullName,
        shouldShow: false,
        avatarProps: getAvatarProps({ fullName, thumbnailUrl }),
        navItems: [
            adminLink(serverUrl ?? ''),
            tikibarLink(serverUrl ?? ''),
            featureFlagsLink(serverUrl ?? ''),
            { type: DIVIDER },
            ticketsSubLink(serverUrl ?? '', publicId, numOrders),
            creditsLink(serverUrl ?? ''),
            likesSubLink(serverUrl ?? '', publicId),
            followingLink(serverUrl ?? '', publicId),
            interestsLink(serverUrl ?? ''),
            { type: DIVIDER },
            accountSettingsLink(serverUrl || ''),
            logOutLink({ logoutPath: logoutUrl ?? '', email }),
        ],
    };
};

export const creatorAvatarDropdown = ({
    env,
    user,
}: {
    env: Partial<CoreEnv>;
    user: UserPropsInterface;
}) => {
    const { fullName, thumbnailUrl, publicId, numOrders, email } = user;
    const { serverUrl, logoutUrl } = env;
    return linkCreator({
        content: fullName,
        shouldShow: false,
        avatarProps: getAvatarProps({ fullName, thumbnailUrl }),
        navItems: [
            browseEventsLink(serverUrl ?? ''),
            { type: DIVIDER },
            ticketsSubLink(serverUrl ?? '', publicId, numOrders),
            likesSubLink(serverUrl ?? '', publicId),
            followingLink(serverUrl ?? '', publicId),
            interestsLink(serverUrl ?? ''),
            { type: DIVIDER },
            accountSettingsLink(serverUrl ?? ''),
            logOutLink({ logoutPath: logoutUrl ?? '', email }),
        ],
    });
};
