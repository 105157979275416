import { gettext } from '@eventbrite/i18n';
import classNames from 'classnames';
import React from 'react';
import { v4 as UUID } from 'uuid';
import { DesktopDropdown, NavLink } from '../..';
import { isTicketAccessMVPEnabled } from '../../../../utils';
import { NavItemType } from '../../../types';
import styles from './NavItemList.module.scss';
export interface NavItemListProps {
    inSearchDropdown?: boolean;
    navItems?: NavItemType[];
    serverUrl: string;
    signinUrl: string;
    signupUrl: string;
    findMyTicketsUrl: string;
}

const NavItemList: React.FC<NavItemListProps> = ({
    inSearchDropdown = false,
    navItems,
    serverUrl,
    signinUrl,
    signupUrl,
    findMyTicketsUrl,
}) => {
    const mainClassnames = classNames(styles.main, {
        [styles.withSearchDropdown]: inSearchDropdown,
    });

    const authLinkClassNames = classNames(styles.list, styles.authLink);
    const showFindMyTickets = isTicketAccessMVPEnabled();
    return (
        <ul className={mainClassnames}>
            {navItems?.map((item) => (
                <li key={UUID()} className={styles.list}>
                    {'path' in item ? (
                        <NavLink
                            path={new URL(item.path, serverUrl ?? '').href}
                            heapIdentifier={item.heapIdentifier}
                            translatedText={item.translatedText}
                            textColor={item.textColor}
                            iconType={item.iconType}
                            iconPosition={item.iconPosition}
                            className={item.className}
                        />
                    ) : (
                        <DesktopDropdown
                            dropdownItems={item.dropdownItems}
                            translatedText={item.translatedText}
                            heapIdentifier={item.heapIdentifier}
                        />
                    )}
                </li>
            ))}
            {showFindMyTickets && (
                <li className={authLinkClassNames}>
                    <NavLink
                        path={findMyTicketsUrl}
                        heapIdentifier={'find-my-tickets'}
                        translatedText={gettext('Find my tickets')}
                        className={styles.findMyTickets}
                    />
                </li>
            )}
            {/* Auth Links */}
            <li className={authLinkClassNames}>
                <NavLink
                    path={signinUrl}
                    heapIdentifier={'login'}
                    translatedText={gettext('Log In')}
                    className={styles.login}
                />
            </li>
            <li className={authLinkClassNames}>
                <NavLink
                    path={signupUrl}
                    heapIdentifier={'signup'}
                    translatedText={gettext('Sign Up')}
                    className={styles.signup}
                />
            </li>
        </ul>
    );
};

export default NavItemList;
