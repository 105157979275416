import { HamburgerMenu } from '@eventbrite/eds-iconography';
import React from 'react';
import { NavigationItemPropsInterface } from '../../constants/interfaces';
import NavigationDropdown from '../NavigationDropdown';

const NavigationMobile = ({
    navigationItems,
}: {
    navigationItems: NavigationItemPropsInterface[];
}) => {
    // use Dropdown with VerticalDotsChunkySvg
    const collapsibleItemList = navigationItems.filter(
        (navigationProps) => navigationProps.collapseOnNarrow,
    );
    return (
        <NavigationDropdown
            content="Mobile Dropdown"
            navItems={collapsibleItemList}
            iconType={<HamburgerMenu />}
        />
    );
};

export default NavigationMobile;
