import { ThemeTokens, VARIANTS } from '../../types';
import {
    ACCESSIBLE_BRAND_ORANGE,
    BLACK,
    BRAND_PURPLE,
    styles as coreStyles,
    TRANSPARENT,
    WHITE,
} from './core-theme';

const VIBRANT_BLUE = '#0124E9';
const VIBRANT_LIGHT_BLUE = '#8FF2F7';
const VIBRANT_YELLOW = '#F7F154';
const MELLOW_BLUE = '#324B8C';

const styles: Record<VARIANTS, Record<ThemeTokens, any>> = {
    ...coreStyles,
    mood_01: {
        background: {
            background: VIBRANT_BLUE,
        },
        'overlay-gradient': {
            color: BLACK,
            background: VIBRANT_BLUE,
        },
        ctaLink: {
            border: VIBRANT_YELLOW,
            color: WHITE,
        },
        'card-ctaLink': {
            border: ACCESSIBLE_BRAND_ORANGE,
            color: BRAND_PURPLE,
        },
        text: {
            color: WHITE,
            underline: VIBRANT_YELLOW,
        },
        'card-text': {
            color: BRAND_PURPLE,
            underline: ACCESSIBLE_BRAND_ORANGE,
        },
        card: {
            background: WHITE,
        },
        'button-primary': {
            background: VIBRANT_YELLOW,
            color: BRAND_PURPLE,
            fill: BRAND_PURPLE,
            border: `2px solid ${TRANSPARENT}`,
            hover: {
                background: VIBRANT_YELLOW,
            },
            focus: {
                background: VIBRANT_YELLOW,
            },
            active: {
                background: VIBRANT_YELLOW,
            },
        },
        'button-secondary': {
            background: TRANSPARENT,
            color: WHITE,
            fill: WHITE,
            border: `2px solid ${WHITE}`,
            hover: {
                background: TRANSPARENT,
            },
            focus: {
                background: TRANSPARENT,
            },
            active: {
                background: TRANSPARENT,
            },
        },
        'expansion-panel-content':
            coreStyles.core_light['expansion-panel-content'],
        'expansion-panel-heading':
            coreStyles.core_light['expansion-panel-heading'],
        'expansion-panel-trigger':
            coreStyles.core_light['expansion-panel-trigger'],
        divider: coreStyles.core_light.divider,
        'text-v2': coreStyles.core_light['text-v2'],
        'background-v2': coreStyles.core_light['background-v2'],
    },
    mood_02: {
        background: {
            background: VIBRANT_LIGHT_BLUE,
        },
        'overlay-gradient': {
            color: WHITE,
            background: VIBRANT_LIGHT_BLUE,
        },
        ctaLink: {
            border: MELLOW_BLUE,
            color: BRAND_PURPLE,
        },
        'card-ctaLink': {
            border: ACCESSIBLE_BRAND_ORANGE,
            color: BRAND_PURPLE,
        },
        text: {
            color: BRAND_PURPLE,
            underline: MELLOW_BLUE,
        },
        'card-text': {
            color: BRAND_PURPLE,
            underline: ACCESSIBLE_BRAND_ORANGE,
        },
        card: {
            background: WHITE,
        },
        'button-primary': {
            background: VIBRANT_BLUE,
            color: WHITE,
            fill: WHITE,
            border: `2px solid ${TRANSPARENT}`,
            hover: {
                background: VIBRANT_BLUE,
            },
            focus: {
                background: VIBRANT_BLUE,
            },
            active: {
                background: VIBRANT_BLUE,
            },
        },
        'button-secondary': {
            background: TRANSPARENT,
            color: BRAND_PURPLE,
            fill: BRAND_PURPLE,
            border: `2px solid ${BRAND_PURPLE}`,
            hover: {
                background: TRANSPARENT,
            },
            focus: {
                background: TRANSPARENT,
            },
            active: {
                background: TRANSPARENT,
            },
        },
        'expansion-panel-content':
            coreStyles.core_light['expansion-panel-content'],
        'expansion-panel-heading':
            coreStyles.core_light['expansion-panel-heading'],
        'expansion-panel-trigger':
            coreStyles.core_light['expansion-panel-trigger'],
        divider: coreStyles.core_light.divider,
        'text-v2': coreStyles.core_light['text-v2'],
        'background-v2': coreStyles.core_light['background-v2'],
    },
};

export const getThemeTokens = (token: ThemeTokens, variant: VARIANTS) => {
    return styles[variant][token];
};
